import React, { useState, useEffect } from 'react';
import {  Container, Button  } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UpcomingService from './UpcomingService';
import Announcement from './Announcement';
import FadeInSection from '../../../Components/FadeInSection'; 


const Schedule = () => {
    const [isSticky, setIsSticky] = useState(false);

    return (
        <Container fluid className='bg-light' style={{width: "100%", overflowX:"hidden"}}>
            <Container className='activity text-dark' style={{paddingBottom:"100px"}}>
                <Row style={{ paddingTop:"40px", paddingBottom:"20px"}}>
                    <Col xs={12} sm={12}><h1 className='text-dark' style={{ textAlign:"left", marginBottom:"50px"}}>Our Services</h1></Col>
                    <Col xs={12} sm={12} md={12}>
                        <FadeInSection direction="left">
                        <UpcomingService />
                        </FadeInSection>
                    </Col>
                
                </Row>
            </Container>
          
        </Container>
    );

};

export default Schedule;