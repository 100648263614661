import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayerComponent from './AudioPlayerComponent';
import AudioMetadataComponent from './AudioMetadataComponent';
import './Audio.css';

const AudioBannerComponent = () => {
  //const [isSticky, setIsSticky] = useState(false);
  //const [textColor, setTextColor] = useState(false);


  // Add/remove scroll event listener
  useEffect(() => {
    
  }, []);

  return (
    <Container fluid>
        <Row style={{  background:" #282c34"}}>
            <Col  xs={12} sm={4} style={{  paddingTop:"15px", paddingRight:"15x", paddingBottom:"15px"}}>
                <AudioMetadataComponent />
            </Col>
            <Col xs={12} sm={8} >
                <AudioPlayerComponent/> 
                                 
            </Col>
        </Row>
    </Container>
  );
};

export default AudioBannerComponent;
