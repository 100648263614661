import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonComponent from './ButtonComponent';
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const OurGroups = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [carouselItems, setCarouselItems] = useState([]);
    const { db } = useFirebase();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
      

    const items =  carouselItems.map((item, index) => (
    <div key={index} className="item">
        <img src={item.image} alt={item.title} height="150px"/>
        <p>{item.title}</p>
    </div>
    ))

    useEffect(() => {
        const fetchGroups = async () => {
            const groupsCollection = collection(db, 'groups');
            const groupsQuery = query(groupsCollection, where('type', 'in', ['interest', 'generational']));
            const querySnapshot = await getDocs(groupsQuery);
            //console.log(querySnapshot);
            const items = querySnapshot.docs.map(doc => {
                const data = doc.data();
                // console.log(data);
                return {
                    image: data.profilePicUrl,
                    title: data.name // Assuming there's a 'name' field for the group
                };
            });

            setCarouselItems(items);
        };

        fetchGroups();
    }, [db]);

    // console.log(carouselItems);
    return (
        <Row style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <Col xs={12} sm={12} md={12}>
                <h1 className="alignleft .mb-10">Our Groups</h1>
            </Col>
            <Col xs={12} sm={12} md={6} style={{ textAlign: "left", paddingTop: "30px" }}>
                <p>Grow in faith and community through our diverse groups at Emmanuel Presbyterian Church. 
                    Connect with others who share similar interests, passions, and life stages, 
                    and experience the joy of meaningful relationships. 
                     <p/>
                    <p>
                    From spiritual growth and fellowship to service and outreach, our groups provide a 
                    space to deepen your connection with God and one another. Explore, 
                    join, and get plugged in to find belonging, support, and encouragement
                    </p>
                  
                </p>
                
            </Col>
            <Col xs={12} sm={12} md={6} style={{ paddingTop: "50px" , paddingBottom: "10px" }}>
                <Carousel 
                    responsive={responsive}
                    swipeable={true}
                    draggable={false}
                    infinite={true}
                    autoPlay={true}
                >
                    {items}
                </Carousel>
            </Col>
            <Col xs={12} sm={6} style={{ textAlign: "left" }}>
            {/*
                <ButtonComponent
                    width="200px"
                    height="35px"
                    borderWidth="2px"
                    borderColor="var(--bluecolor)"
                    backgroundColor="var(--bodybgcolorwhite)"
                    color="#333"
                    fontSize="14px"
                    iconSize="24px"
                    iconColor="red"
                >
                    VIEW GROUPS
                </ButtonComponent>
            */}
                
            </Col>
        </Row>
    );
};

export default OurGroups;