import React from 'react';
import styled from 'styled-components';

const BlockWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const ImageLinkContainer = styled.a`
  display: block;
  height: 80px;
  width: 80px;
  margin-right: 1rem;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;

  ${ImageLinkContainer}:hover & {
    transform: scale(1.05);
  }
`;

const TextContent = styled.div`
  flex: 1;
  color: rgba(255, 255, 255, 0.7);
`;

const Heading = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.7);

  a {
    color: var(--txtcolor);
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;


    &:hover {
      color: var(--redcolor); // Assuming you have a --redcolor variable defined
    }
  }
`;

const Meta = styled.div`
  display: flex;
  font-size: 14px;
`;

const MetaItem = styled.div`
  margin-right: 10px;
  color: gray;
  
  &:last-child {
    margin-right: 0;
  }

  a {
    color: gray;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--redcolor); // Same hover color as the heading
    }
  }
`;

const BlogPost = ({ imageUrl,href, alt ,title, date, author, comments }) => {
  return (
    <BlockWrapper className="block-21">
      <ImageLinkContainer href={href} className="img rounded">
        <Image src={imageUrl} alt={alt} />
      </ImageLinkContainer>
      <TextContent>
        <Heading>
          <a href={href}>{title}</a>
        </Heading>
        <Meta>
          <MetaItem>{date}</MetaItem>
          <MetaItem>{author}</MetaItem>
          <MetaItem>{comments}</MetaItem>
        </Meta>
      </TextContent>
    </BlockWrapper>
  );
};

export default BlogPost;