import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { FiChevronUp } from 'react-icons/fi';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './Events.css';

const DateRangePicker = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setIsStartCalendarOpen(false);
    onDateRangeChange(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndCalendarOpen(false);
    onDateRangeChange(startDate, date);
  };

  const CustomInput = ({ value, onClick, isOpen }) => (
    <div 
      className="custom-input d-flex justify-content-between align-items-center p-3 border rounded"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <span>{formatDate(value)}</span>
      <FiChevronUp className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
    </div>
  );

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "8rem" }}>
            <div className='flex-row'> 
              <span>FROM : </span>
              <span>
                <DatePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                  format="y-MM-dd"
                  calendarIcon={null}
                  clearIcon={null}
                  onCalendarOpen={() => setIsStartCalendarOpen(true)}
                  onCalendarClose={() => setIsStartCalendarOpen(false)}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  className='react-date-picker__wrapper react-date-picker-input'
                  customInput={
                    <CustomInput
                      value={startDate}
                      isOpen={isStartCalendarOpen}
                    />
                  }
                />
              </span>
            </div>   
            <div className='flex-row'> 
              <span>TO : </span>
              <span>
                <DatePicker
                  onChange={handleEndDateChange}
                  value={endDate}
                  format="y-MM-dd"
                  calendarIcon={null}
                  clearIcon={null}
                  onCalendarOpen={() => setIsEndCalendarOpen(true)}
                  onCalendarClose={() => setIsEndCalendarOpen(false)}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  className='react-date-picker__wrapper react-date-picker-input'
                  customInput={
                    <CustomInput
                      value={endDate}
                      isOpen={isEndCalendarOpen}
                    />
                  }
                />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DateRangePicker;