import React from 'react';

const SlugGenerator = ({ title }) => {

  const createSlug = (title) => {
    // Remove special characters and replace spaces with hyphens
    let slugifiedTitle = title.toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-|-$/g, '');

    // Ensure the slug is not too long
    if (slugifiedTitle.length > 60) {
      const truncatedTitle = slugifiedTitle.substr(0, 60);
      const lastHyphenIndex = truncatedTitle.lastIndexOf('-');
      if (lastHyphenIndex !== -1) {
        slugifiedTitle = truncatedTitle.substr(0, lastHyphenIndex);
      } else {
        slugifiedTitle = truncatedTitle;
      }
    }

    return slugifiedTitle;
  };

  let slug = createSlug(title);
  return slug;
};

export default SlugGenerator;
