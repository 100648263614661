import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SlArrowDown } from "react-icons/sl";

const PurpleNav = ({ menuItems }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderMenuItem = (item, index) => {
    if (item.children) {
      const isGroupDropdown = item.label === 'Our Groups';
      return (
        <DropdownItem key={index} ref={dropdownRef}>
          <DropdownToggle
            onClick={() => handleDropdownToggle(index)}
            className={openDropdown === index ? 'active' : ''}
            isopen={openDropdown === index ? 'true' : 'false'}
          >
            {item.label}
            <ArrowIcon isopen={openDropdown === index ? 'true' : 'false'}>
              <SlArrowDown />
            </ArrowIcon>
          </DropdownToggle>
          <DropdownMenu 
            className={openDropdown === index ? 'show' : openDropdown === null ? '' : 'hide'}
            isGroupDropdown={isGroupDropdown}
          >
            {isGroupDropdown ? (
              <ScrollableContainer>
                {item.children.map((child, childIndex) => (
                  <DropdownMenuItem key={childIndex} isGroupItem={isGroupDropdown}>
                    <Link to={child.href} onClick={() => setOpenDropdown(null)} className={location.pathname === child.href ? 'active' : ''}>
                      {child.profilePicUrl && (
                        <GroupLogo src={child.profilePicUrl} alt={child.label} />
                      )}
                      <TextWithUnderline>
                        {child.label}
                        <Underline />
                      </TextWithUnderline>
                    </Link>
                  </DropdownMenuItem>
                ))}
              </ScrollableContainer>
            ) : (
              item.children.map((child, childIndex) => (
                <DropdownMenuItem key={childIndex}>
                  <Link to={child.href} onClick={() => setOpenDropdown(null)} className={location.pathname === child.href ? 'active' : ''}>
                    <TextWithUnderline>
                      {child.label}
                      <Underline />
                    </TextWithUnderline>
                  </Link>
                </DropdownMenuItem>
              ))
            )}
          </DropdownMenu>
        </DropdownItem>
      );
    } else {
      return (
        <MenuItem key={index}>
          <Link to={item.href} className={location.pathname === item.href ? 'active' : ''}>
            {item.label}
          </Link>
        </MenuItem>
      );
    }
  };

  return (
    <NavContainer>
      <NavList>{menuItems.map((item, index) => renderMenuItem(item, index))}</NavList>
    </NavContainer>
  );
};

export default PurpleNav;

const NavContainer = styled.nav`
  background-color: none;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

const MenuItem = styled.li`
  margin-right: 1.5rem;
  position: relative;

  a {
    color: whitesmoke;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 0;
    transition: color 0.3s ease;

    &:hover {
      color: var(--redcolor);
    }

    &.active {
      color: var(--bodybgcolorwhite);
    }

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      margin-bottom: -20px;
      background-color: var(--redcolor);
      transition: width 0.3s ease;
    }

    &:hover::after, &.active::after {
      width: 100%;
    }
  }
`;

const DropdownItem = styled(MenuItem)`
  position: relative;

  a {
    &::after {
      content: none;
    }
  }
`;

const DropdownToggle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: whitesmoke;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--redcolor);
  }

  &.active {
    color: var(--bodybgcolorwhite);
  }
`;

const ArrowIcon = styled.span`
  display: inline-flex;
  margin-left: 0.5em;
  transition: transform 0.3s ease;
  transform: ${props => props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 0.5rem 0;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 150px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;

  ${props => props.isGroupDropdown && `
    min-width: 300px;
    max-height: 400px;

  `}

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
`;

const ScrollableContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 280px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color:  #f0f0f0;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--redcolor);
    border-radius: 4px;
  }
`;

const DropdownMenuItem = styled.li`
  a {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #333;
    text-align: left;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    position: relative;

    &:hover, &.active {
      color: var(--redcolor);
      transform: translateX(10px);
    }

    &::after {
      content: none;
    }
  }

  ${props => props.isGroupItem && `
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

const GroupLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const TextWithUnderline = styled.span`
  position: relative;
  display: inline-block;
`;

const Underline = styled.span`
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--redcolor);
  transition: width 0.3s ease;

  ${DropdownMenuItem} a:hover &,
  ${DropdownMenuItem} a.active & {
    width: 100%;
  }
`;


/*
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SlArrowDown } from "react-icons/sl";
import './PurpleNav.css';

const PurpleNav = ({ menuItems }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderMenuItem = (item, index) => {
    if (item.children) {
      const isGroupDropdown = item.label === 'Our Groups';
      return (
        <li key={index} className="dropdown-item" ref={dropdownRef} style={{marginRight:"20px"}}>
          <button
            onClick={() => handleDropdownToggle(index)}
            className={`dropdown-toggle ${openDropdown === index ? 'active' : ''}`}
            data-isopen={openDropdown === index ? 'true' : 'false'}
          >
            {item.label}
            <span className={`arrow-icon ${openDropdown === index ? 'open' : ''}`}>
              <SlArrowDown />
            </span>
          </button>
          <div 
            className={`dropdown-menu ${openDropdown === index ? 'show' : ''} ${isGroupDropdown ? 'group-dropdown' : ''}`}
          >
            {isGroupDropdown ? (
              <ul className="scrollable-container">
                {item.children.map((child, childIndex) => (
                  <li key={childIndex} className="dropdown-menu-item group-item">
                    <Link to={child.href} onClick={() => setOpenDropdown(null)} className={location.pathname === child.href ? 'active' : ''}>
                      {child.profilePicUrl && (
                        <img src={child.profilePicUrl} alt={child.label} className="group-logo" />
                      )}
                      <span className="text-with-underline">
                        {child.label}
                        <span className="underline"></span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              item.children.map((child, childIndex) => (
                <li key={childIndex} className="dropdown-menu-item">
                  <Link to={child.href} onClick={() => setOpenDropdown(null)} className={location.pathname === child.href ? 'active' : ''}>
                    <span className="text-with-underline">
                      {child.label}
                      <span className="underline"></span>
                    </span>
                  </Link>
                </li>
              ))
            )}
          </div>
        </li>
      );
    } else {
      return (
        <li key={index} className="menu-item">
          <Link to={item.href} className={location.pathname === item.href ? 'active' : ''}>
            {item.label}
          </Link>
        </li>
      );
    }
  };

  return (
    <nav className="nav-container">
      <ul className="nav-list">
        {menuItems.map((item, index) => renderMenuItem(item, index))}
      </ul>
    </nav>
  );
};

export default PurpleNav;
*/