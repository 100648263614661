import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaRocket, FaSatellite, FaSpaceShuttle, FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const EventNotFound = () => {
  return (
    <Container fluid className="bg-dark text-light min-vh-100 d-flex align-items-center">
      <Row className="w-100">
        <Col xs={12} className="text-center">
          <FaRocket className="text-danger mb-4" size={100} />
          <h1 className="display-1 fw-bold text-light">404</h1>
          <h2 className="mb-4 text-light">Oops! This event post has drifted away</h2>
          <p className="lead mb-5" style={{color:"var(--redcolor)"}}>
            <FaSatellite className="me-2" />
            We've lost it. Please check  out our other events and participate. Thank you
            <FaSatellite className="ms-2" />
          </p>
         
          <Button as={Link} to="/events" variant="outline-light" size="lg" className="mt-3">
            <FaRocket className="me-2" />
            Return to Events
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EventNotFound;