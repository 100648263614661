import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ScheduleCard = ({ groupId }) => {
    const { db } = useFirebase();
    const [meetingData, setMeetingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const docRef = doc(db, 'groupMeetings', groupId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setMeetingData(docSnap.data().meetings);
                } else {
                    setError('');
                }
            } catch (err) {
                console.error('Error fetching group schedule data:', err);
                setError('Failed to fetch group schedule data');
            } finally {
                setLoading(false);
            }
        };

        fetchMemberData();
    }, [db, groupId]);

    if (loading) {
        return <span>Loading...</span>;
    }

    if (error) {
        return <p >{error}</p>;
    }

    if (!meetingData) {
        return null;
    }

    return (
        <Row className="justify-content-between align-items-center mb-4">
            <Col sm={12} style={{paddingLeft:"5%"}}>
                <h2 className="text-dark" style={{textAlign:"left"}}>Meeting Schedule</h2>
                <h3 className="text-danger" style={{textAlign:"left"}}>Current meeting days and times</h3>
            </Col>
            <Col sm={12} style={{paddingLeft:"5%", paddingRight:"5%"}}>
                <div style={{textAlign:"left", marginTop:"20px"}}>
                    <Row>
                    {meetingData.map((meeting, index) => (
                        <Col  key={index} sm={6} sx={12} style={{marginBottom:"10px"}}>
                            <h4>{meeting.day} </h4>
                            <span>{meeting.startTime} - </span>
                            <span>  {meeting.endTime}</span>
                        </Col>
                    ))}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default ScheduleCard;