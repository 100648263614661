import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PageHeader.css';  // We'll create this CSS file

const ClearHeader = ({ title, breadcrumbs = [] }) => {
    return (
      <div className="clear-header d-flex align-items-center">
        <Container>
          {/* Desktop Layout (≥992px) */}
          <div className="d-none d-lg-block">
            <Row className="align-items-center">
              <Col>
                <h1 className="page-title m-0">{title}</h1>
              </Col>
              <Col className="text-end">
                <div className="breadcrumb-wrapper">
                  {breadcrumbs.map((crumb, index) => (
                    <span key={crumb}>
                      {index > 0 && <span className="mx-2">/</span>}
                      {crumb}
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
  
          {/* Mobile/Tablet Layout (<992px) */}
          <div className="d-block d-lg-none">
            <Row className="align-items-center">
              <Col xs={12}>
                <h1 className="page-title">{title}</h1>
              </Col>
              <Col xs={12}>
                <div className="breadcrumb-wrapper">
                  {breadcrumbs.map((crumb, index) => (
                    <span key={crumb}>
                      {index > 0 && <span className="mx-2">/</span>}
                      {crumb}
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  };
  


/*import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PageHeader.css';  // We'll create this CSS file

const ClearHeader = ({ title }) => {
  return (
    <div 
      className="header-container"
    >
      
     
      <Container fluid className="h-100">
        <Row className="h-100 align-items-center">
          <Col xs={12} md={8} lg={6}>
            <h1 className="clear-headertext text-dark" >{title}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
*/

export default ClearHeader;