import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useFirebase } from '../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import { FaHandshake } from 'react-icons/fa';
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from 'react-icons/bi';
import LazyLoad from 'react-lazyload';

const styles = `
.welcome-section {
  padding: 60px 0;
  background-color: #fff;
}

.section-subtitle {
  color: #4a90e2;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #2c3e50;
}

.welcome-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #5a6a7a;
  padding-top: 40px;
}

blockquote {
    font-size: 1.25rem;
  font-style: italic;
  color: #34495e;
  line-height: 1.6;
  text-align:center;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 2em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
  
}
blockquote p {
  display: inline;
}

.quote-text {
  font-size: 1.25rem;
  font-style: italic;
  color: #34495e;
  line-height: 1.6;
}

.quote-icon {
  color: #4a90e2;
  opacity: 0.5;
}

.leader-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}



.leader-name {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  color: #2c3e50;
}

.leader-title {
  font-size: 0.9rem;
  color: #6c757d;
  margin: 0;
}

@media (max-width: 991.98px) {
  .section-title {
    font-size: 2rem;
  }
  
  .welcome-section {
    padding: 40px 0;
  }
}
`;

const WelcomeSection = () => {
  const [welcome, setWelcome] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { db } = useFirebase();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });

  useEffect(() => {
    const fetchWelcomeMetadata = async () => {
      try {
        setLoading(true);
        const welcomeDoc = await getDoc(doc(db, 'welcomeNote', 'current'));
        if (welcomeDoc.exists()) {
          setWelcome(welcomeDoc.data());
        } else {
          setError('No welcome data found');
        }
      } catch (error) {
        console.error("Error fetching welcome data:", error);
        setError('Failed to fetch welcome data');
      } finally {
        setLoading(false);
      }
    };

    fetchWelcomeMetadata();
  }, [db]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  if (!welcome) {
    return (
      <Container className="text-center mt-5">
        <h3>We are honoured to have you here.</h3>
      </Container>
    );
  }

  return (
    <>
      <style>{styles}</style>
      <section className="welcome-section">
        <Container>
          <Row className="align-items-start">
            <Col lg={6}>
              <div className="pe-lg-4">
                
                <h2 className="section-title" style={{textAlign:'left'}}>
                  {welcome.noteTitle}
                </h2>
                
                <div className="welcome-text mb-4">
                  <p 
                    dangerouslySetInnerHTML={{ 
                      __html: expanded ? welcome.welcomeNote : truncateText(welcome.welcomeNote, 500) 
                    }} 
                  />
                  {welcome.welcomeNote.length > 500 && (
                    <Button 
                      variant="link" 
                      onClick={toggleExpand} 
                      className="p-0 text-decoration-none text-primary" 
                      style={{color:  'var(--redcolor)' }}
                    >
                      {expanded ? 'Show Less' : 'Read More'}
                    </Button>
                  )}
                </div>
                
                <div className="d-flex align-items-start mt-4" style={{textAlign:"left"}}>
                  
                  <div className="leader-info">
                    <p className="leader-name">
                      {welcome.leaderName}
                    </p>
                    <p className="leader-title">
                      {welcome.leaderTitle}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            
            <Col lg={6} className="mt-4 mt-lg-0">
              <div className="position-relative">
                <LazyLoad>
                  <img    
                    src={welcome.leaderImageUrl} // Assuming there's a welcomeImage field in your data
                    alt="Welcome"
                    className="img-fluid  "
                    onError={(e) => {
                      e.target.src = "/api/placeholder/600/400"; // Fallback image
                    }}
                  />
                </LazyLoad>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WelcomeSection;