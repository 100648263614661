import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaQuoteLeft, FaQuoteRight, FaCross } from 'react-icons/fa';
import './motto.css';

const Motto = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid className="motto-wrapper py-5">
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={10} lg={8} className="text-center">
          <div className={`motto-content ${isVisible ? 'visible' : ''}`}>
            <div className="quote-container">
              <FaCross className="cross-icon mb-4" />
              <blockquote className="blockquote position-relative">
                <FaQuoteLeft className="quote-icon quote-left" />
                <p className="motto-text">
                  To be a Christ centered self sustaining and a growing church
                </p>
                <FaQuoteRight className="quote-icon quote-right" />
              </blockquote>
              <div className="motto-divider">
                <span className="divider-line"></span>
                <FaCross className="divider-icon" />
                <span className="divider-line"></span>
              </div>
       
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Motto;

