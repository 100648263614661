import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFirebase } from '../../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';

const AudioMetadataComponent = () => {
  const [fileName, setFileName] = useState('');
  const [uploadedAt, setUploadedAt] = useState('');
  const { db } = useFirebase();

  useEffect(() => {
    const fetchAudioMetadata = async () => {
      try {
        const currentAudioDoc = await getDoc(doc(db, 'currentAudio', 'current'));
        if (currentAudioDoc.exists()) {
          const audioFileId = currentAudioDoc.data().audioFileId;
          const audioFileDoc = await getDoc(doc(db, 'audioFiles', audioFileId));
          if (audioFileDoc.exists()) {
            // Remove file extension from fileName
            const fullFileName = audioFileDoc.data().fileName;
            setFileName(fullFileName.substring(0, fullFileName.lastIndexOf('.')));
            
            // Format the date
            const date = audioFileDoc.data().uploadedAt.toDate();
            setUploadedAt(formatDate(date));
          }
        }
      } catch (error) {
        console.error("Error fetching audio metadata:", error);
      }
    };

    fetchAudioMetadata();
  }, [db]);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <p className='white-text text_wrapper pl2' style={{fontWeight:"700", textAlign:"left"}}>{uploadedAt}: {fileName}</p>
    
  );
};

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const MetadataItem = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const Value = styled.span`
  color: #333;
`;

export default AudioMetadataComponent;