import React, { useState, useEffect } from 'react';
import { Navbar, Container, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useMediaQuery } from 'react-responsive';
import logo from '../../../images/pcg.png';
import './NavBarComponent.css';
import MobileNav from '../../../Components/GeneralNav/MobileNav';
import PurpleNav from '../../../Components/Nav/PurpleNav';
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, getDocs, query, where } from 'firebase/firestore';

const NavBarComponent = () => {
  const { db } = useFirebase();
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const location = useLocation();

  const createSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsCollection = collection(db, 'groups');
      const activeGroupsQuery = query(groupsCollection, where('status', '==', 'Active'));
      const querySnapshot = await getDocs(activeGroupsQuery);
      const fetchedGroups = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name,
          slug: createSlug(data.name)
        };
      });
      setGroups(fetchedGroups);
    };

    fetchGroups();
  }, [db]);

  const menuItems = [
    { label: 'Home', href: '/' },
    {
      label: 'About Us',
      children: [
        { label: 'Know Us', href: '/about/gettoknowus' },
        { label: 'Leadership', href: '/about/leaders' },
        { label: 'Our Story', href: '/about/history' },
      ],
    },
    ...(groups.length > 0 ? [{
      label: 'Our Groups',
      children: groups.map(group => ({
        label: group.name,
        href: `/group/${group.slug}/${group.id}`,
        profilePicUrl: group.profilePicUrl // Add this line
      }))
    }] : []),
    { label: 'Blog', href: '/blog' },
    { label: 'Events', href: '/events' },
    { label: 'Notices', href: '/notices' },
    {
      label: 'Media',
      children: [
        { label: 'Audio', href: '/media/audiocenter' },
        { label: 'Gallery', href: '/media/gallery' },
      ],
    },
    { label: 'Contact', href: '/contact' },
  ];
 

  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsSticky(currentScrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <Navbar expand="lg" className={`${isSticky ? 'sticky-navbar' : 'transparent-navbar'} bg-body-tertiary`} fixed='top' >
    <Container fluid>
      <Navbar.Brand href="#home" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <img src={logo} height="35px" alt="PCG Logo" />
        <div style={{ paddingLeft: "10px" }}>
          <div style={{ fontSize: "11px", textAlign: "left", color: "whitesmoke", fontWeight: "600", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {isSmallScreen ? 'PCG Kasoa' : 'Presbyterian Church of Ghana'}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "700", color: "whitesmoke", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {isSmallScreen ? 'EMMANUEL' : 'Emmanuel Congregation, Kasoa'}
          </div>
        </div>
      </Navbar.Brand>
      <Nav>

      </Nav>
      
      <Nav className="d-none d-lg-flex ml-auto " >
        <PurpleNav menuItems={menuItems}/>
      </Nav>

      <div className="d-lg-none">
        <MobileNav  menuItems={menuItems} />
      </div>
    </Container>
  </Navbar>
  );
};

export default NavBarComponent;
