import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GoArrowRight } from "react-icons/go";

const ButtonComponent = ({
  width = '120px',
  height = '40px',
  borderWidth = '1px',
  borderColor = '#000',
  backgroundColor = '#fff',
  color = '#000',
  fontSize = '16px',
  iconSize = '20px',
  iconColor = 'red',
  to, // new prop for the link target
  children
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    width,
    height,
    borderWidth,
    borderColor,
    backgroundColor,
    color,
    borderRadius: '9999px',
    fontWeight: "400",
    border: `${borderWidth} solid ${borderColor}`,
    cursor: 'pointer',
    fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 15px',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    position: 'relative',
  };

  const contentStyle = {
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.3s ease',
    transform: isHovered ? 'translateX(-10px)' : 'translateX(0)',
  };

  const textStyle = {
    marginRight: isHovered ? '10px' : '0',
    transition: 'margin-right 0.3s ease',
  };

  const iconStyle = {
    opacity: isHovered ? 1 : 0,
    transform: isHovered ? 'translateX(0)' : 'translateX(-20px)',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    fontSize: iconSize,
    color: iconColor
  };

  return (
    <Link to={to} style={buttonStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div style={contentStyle}>
        <span style={textStyle}>{children}</span>
        <GoArrowRight style={iconStyle} />
      </div>
    </Link>
  );
};

export default ButtonComponent;
/*import React, { useState } from 'react';
import { GoArrowRight } from "react-icons/go";

const ButtonComponent = ({ 
  width = '120px', 
  height = '40px', 
  borderWidth = '1px', 
  borderColor = '#000', 
  backgroundColor = '#fff', 
  color = '#000', 
  fontSize = '16px',
  iconSize = '20px',
  iconColor = 'red',
  children 
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    width,
    height,
    borderWidth,
    borderColor,
    backgroundColor,
    color,
    borderRadius: '9999px',
    fontWeight: "400", 
    border: `${borderWidth} solid ${borderColor}`,
    cursor: 'pointer',
    fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 15px',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    position: 'relative',
  };

  const contentStyle = {
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.3s ease',
    transform: isHovered ? 'translateX(-10px)' : 'translateX(0)',
  };

  const textStyle = {
    marginRight: isHovered ? '10px' : '0',
    transition: 'margin-right 0.3s ease',
  };

  const iconStyle = {
    opacity: isHovered ? 1 : 0,
    transform: isHovered ? 'translateX(0)' : 'translateX(-20px)',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    fontSize: iconSize,
    color: iconColor
  };

  return (
    <button 
      style={buttonStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={contentStyle}>
        <span className='truncate-text' style={textStyle}>{children}</span>
        <GoArrowRight style={iconStyle} />
      </div>
    </button>
  );
};


export default ButtonComponent;
*/