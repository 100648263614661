import React from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container } from 'react-bootstrap';
import SEO from '../Components/SEO';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import Timeline from '../Layouts/About/Timeline';


const Histroy = () => {
  return (
    <>
        <SEO
            title='Our Story'
            description='The official website of the Emmanuel Presbyterian Church Kasoa'
            name='Emmnuel Presbyterian Church.'
            type='profile' 
        />
        <NavBarComponent />
        <ClearHeader 
            title="Our Journey" 
            breadcrumbs={['Home', 'History']} 
        />
        <Container style={{marginTop:"60px"}}>
            <Timeline />
         
        </Container>
      
      <Footer />
    </>
  );
};

export default Histroy;