import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import './Events.css';
import { useFirebase } from '../../Context/FirebaseContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { color } from 'framer-motion';

const MonthCalendar = () => {
  const { db } = useFirebase();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [calendar, setCalendar] = useState([]);
  const [events, setEvents] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showEventDetails, setShowEventDetails] = useState(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  useEffect(() => {
    generateCalendar(date);
    fetchEvents(date);
  }, [date]);

  const fetchEvents = async (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    const q = query(
      collection(db, 'events'),
      where('startDate', '>=', startDate.toISOString().substring(0, 10)),
      where('startDate', '<=', endDate.toISOString().substring(0, 10))
    );

    const querySnapshot = await getDocs(q);
    const events = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setEvents(events);
  };

  const generateCalendar = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay();

    let calendarDays = [];
    let day = 1;

    for (let i = 0; i < 6; i++) {
      let week = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startingDay) {
          week.push(null);
        } else if (day > daysInMonth) {
          week.push(null);
        } else {
          week.push(day);
          day++;
        }
      }
      if (week.some(day => day !== null)) {
        calendarDays.push(week);
      }
    }
    // console.log(calendarDays.flat()); // meaning the whole days in an array starting with index 0 
    //so in using the flat and comparing to the dayindex always the key must be  - 1
    setCalendar(calendarDays);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const goToPreviousMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  };

  const goToNextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  };

  const goToCurrentMonth = () => {
    setDate(new Date());
  };

  const getDayName = (day, index) => {
    const fullNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const abbreviatedNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return windowWidth <= 768 ? abbreviatedNames[index] : fullNames[index];
  };

  const handleEventClick = (eventname, eventId) => {
    navigate(`/events/${createSlug(eventname)}/${eventId}`);
  };

  const  createSlug = (string) => {
    string = string.toLowerCase();
    string = string.replace(/[^a-z0-9 ]/g, '');
    string = string.replace(/\s+/g, '-');
    string = string.replace(/^-|-$/g, '');
    if (string === '') {
      return 'slug'; 
    }
  
    return string;
  } 

  const handleEventDetailsToggle = (dayIndex) => {
    console.log(dayIndex);
    setShowEventDetails(dayIndex === showEventDetails ? null : dayIndex);
  };

  const renderEventContent = (day, isMobile) => {
    const dayEvents = events.filter(event => new Date(event.startDate).getDate() === day && new Date(event.startDate).getMonth() === date.getMonth());
    
    if (isMobile) {
      return dayEvents.length > 0 && (
        <div className="event-dot" onClick={() => handleEventDetailsToggle(day)}></div>
      );
    } else {
      return dayEvents.map(event => (
        <OverlayTrigger
          key={event.id}
          placement="top"
          overlay={
            <div id={`tooltip-${event.id}`}>
              <div className="event-details-tooltip">
                <img src={event.imageUrl} alt={event.eventName} />
                <h6 onClick={() => handleEventClick(event.eventName, event.id)} className="event-name">
                  {event.eventName.length > 30 ? `${event.eventName.slice(0, 30)}...` : event.eventName}
                </h6>
                <p>{`${event.startDate} @ ${event.startTime}`}</p>
              </div>
            </div>
          }
          trigger={['hover', 'focus']}
          rootClose={false}
        >
          <div className="event-name-container" onClick={() => handleEventClick(event.eventName, event.id)}>
            <div className="event-time" style={{fontSize:'12px'}}>{event.startTime} - {event.endTime}</div>
            <div className="event-name" style={{fontSize: '1.5vw'}}>
              {event.eventName.length > 8 ? `${event.eventName.slice(0, 8)}...` : event.eventName}
            </div>
            
          </div>
        </OverlayTrigger>
      ));
    }
  };

  const isMobile = windowWidth <= 768;

  return (
    <Container fluid className="month-calendar">
    <div className='flex-row-month mb-4' style={{width:'100%'}}>
      <div className='flex-row-month d-none d-md-flex' style={{width:'100%'}}>
        <Button onClick={goToPreviousMonth} className="nav-button" variant="link" style={{fontSize:"1.5rem",paddingLeft: '0px', paddingRight:"0px"}}>
          <FaChevronLeft /> 
        </Button>
        <Button onClick={goToNextMonth} className="nav-button" variant="link" style={{fontSize:"1.5rem",paddingLeft: '0px', paddingRight:"0px"}}>
          <FaChevronRight />
        </Button>
        <Button onClick={goToCurrentMonth} className="nav-button" variant="link" style={{ fontSize:"1.5rem", textDecoration:"none"}}>
          This Month
        </Button>
      </div>
      <div className="date-picker-container" style={{width:'100%'}}>
        <DatePicker
          selected={date}
          onChange={handleDateChange}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          className="custom-datepicker"
        />
      </div>
    </div>

      <div className="calendar-grid">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
          <div key={day} className="calendar-header">
            {getDayName(day, index)}
          </div>
        ))}
        {calendar.flat().map((day, index) => (
          <div key={index} className="calendar-day">
            {day && (
              <>
                <span className="day-number">{day}</span>
                {renderEventContent(day, isMobile)}
              </>
            )}
          </div>
        ))}
      </div>
      
      {isMobile &&  showEventDetails !== null && (
        <div className='alignleft mt-2'>
          {events
            .filter(event => new Date(event.startDate).getDate() === calendar.flat()[showEventDetails - 1] && new Date(event.startDate).getMonth() === date.getMonth())
            .map(event => (
              <div key={event.id} className="event-details">
                <div> <img src={event.imageUrl} width='100%' alt='event.eventName' /></div>
                <p className='mt-1'>{`${new Date(event.startDate).toLocaleDateString('en-US', { month: 'long' })} ${new Date(event.startDate).getDate()} @ ${event.startTime}`}</p>
                <h6><span class='event-name-link' onClick={() => handleEventClick(event.eventName, event.id)}>{event.eventName}</span></h6>
              </div>
            ))}
        </div> 
      )}

      <div className='separator mt-2 mb-2 d-md-none'></div>
      <Row className='d-md-none'>
        <Col xs={4} className='alignleft'>
          <Button onClick={goToPreviousMonth} className="nav-button" style={{paddingLeft:"0px"}}> 
            <FaChevronLeft /> Previous
          </Button>
        </Col>
        <Col xs={4}>
          <Button onClick={goToCurrentMonth} className="nav-button">
            This Month
          </Button>
        </Col>
        <Col xs={4} className='alignright' >
          <Button onClick={goToNextMonth} className="nav-button" style={{paddingRight:"0px"}}>
            Next <FaChevronRight />
          </Button>
        </Col>
      </Row>
     
    </Container>
  );
};

export default MonthCalendar;