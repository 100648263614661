import React from 'react';
import styled from 'styled-components';
import { FaMap } from "react-icons/fa6";
import { MdCall } from "react-icons/md";
import { MdAlternateEmail } from "react-icons/md";

const Block = styled.div`
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const IconWrapper = styled.span`
  margin-right: 1rem;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
`;

const Text = styled.span`
  color: rgba(255, 255, 255, 0.7);
  text-overflow: ellipsis;
  width:100%;
  text-align:left;
`;

const Link = styled.a`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;

  &:hover {
    color: white;
  }
`;

const ContactInfo = () => {
  return (
    <Block className="block-23">
      <List>
        <ListItem>
          <IconWrapper>
            <FaMap />
          </IconWrapper>
          <Text>P. O. Box WU 60, <br/>
          Road of Justice,<br/>
            Kasoa Central Region,<br/>
            Digital Address:  CX-002-1976
            </Text>
          
          
        </ListItem>
        <ListItem>
          <Link href="tel:+23923929210">
            <IconWrapper>
              <MdCall />
            </IconWrapper>
            <Text>+233 24 467 7147<br/>
            +233 26 457 6583
            <br/>
            +233 24 325 0605
            
            </Text>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="mailto:pcgemmanuelkasoa@gmail.com">
            <IconWrapper>
              <MdAlternateEmail />
            </IconWrapper>
            <Text >pcgemmanuelkasoa@gmail.com</Text>
          </Link>
        </ListItem>
      </List>
    </Block>
  );
};

export default ContactInfo;