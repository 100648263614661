import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';

const FetchNameById = ({ collectionName, documentId }) => {
  const { db } = useFirebase();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchName = async () => {
      if (!documentId) {
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, collectionName, documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const fullName = data.name  ? data.name  : `${data.firstName} ${data.lastName}`;
          setName(fullName || 'Name not defined');
        } else {
          setName('Document not found');
        }
      } catch (err) {
        console.error('Error fetching document:', err);
        setError('Failed to fetch name');
      } finally {
        setLoading(false);
      }
    };

    fetchName();
  }, [db, collectionName, documentId]);

  if (loading) {
    return <span>Loaoding ..</span> ;
  }

  if (error) {
    return <p style={{ color :"red"}}>{error}</p>;
  }

  return <span>{name}</span>;
};

export default FetchNameById;
