import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FcLike } from "react-icons/fc";
import FadeInSection from '../../../Components/FadeInSection';  // Make sure to import this
import "./calltoaction.css";

const CallToAction = () => {
    return (
        <Container>
            <Row style={{ paddingTop:"40px", paddingBottom:"40px"}}>
                <FadeInSection direction="left">
                    <Col xs={12} sm={12}>
                        <h4 className="call-to-action-text">Hey, please support our endeavours in helping build and 
                            supporting our church and community
                        </h4>
                    </Col>
                </FadeInSection>
                <FadeInSection direction="right">
                    <Col xs={12} sm={6} md={4}>
                        <div className="d-grid gap-2">
                            <a href="#" className="button type--C">
                                <div className="button__line"></div>
                                <div className="button__line"></div>
                                <span className="button__text">Support Us <FcLike /></span>
                            </a>
                        </div>
                    </Col>
                </FadeInSection>
            </Row>
        </Container>
    );
};

export default CallToAction;