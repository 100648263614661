import React from 'react';
import { Card } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const TeamMemberCard = ({ name, role, imageUrl }) => {
  // console.log(imageUrl);
  return (
    <Card className="team-member-card" style={{
      width: '100%',
      maxWidth: '297px',
      height: '400px',
      maxHeight: '400px',
      aspectRatio: '297 / 411',
      borderRadius: '20px',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '6.57% 6.67% 31.24%',
      boxSizing: 'border-box'
    }}>
      <div className="member-image" style={{
        width: '100%',
        paddingBottom: '100%',
         position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        marginBottom: '1rem'
      }}>
        <img 
          src={imageUrl} 
          alt={name} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </div>
      <Card.Body style={{ 
        width: '100%',
        textAlign: 'center',
       
      }}>
        <Card.Title className='truncate-text' style={{
          fontWeight: 700,
          fontSize:"18px",
        }}>{name}</Card.Title>
        <Card.Text style={{
          fontStyle: 'italic',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '12px',
          color: '#373232',
          marginBottom: '1rem'
        }}>{role}</Card.Text>
        <div className="social-icons" style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
          gap: '0.5rem',
          color: '#373232',
          fontSize: '12px',
        }}>
          <FaFacebookF />
          <FaTwitter />
          <FaInstagram />
          <FaLinkedinIn />
        </div>
      </Card.Body>
    </Card>
  );
};

export default TeamMemberCard;
/*
import React from 'react';
import { Card } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const TeamMemberCard = ({ name, role, imageUrl }) => {
  return (
    <Card className="team-member-card" style={{ width: '297.44px', height: '411.61px', borderRadius: '20px', overflow: 'hidden', position: 'relative' }}>
      <div className="member-image" style={{
        position: 'absolute',
        left: '6.67%',
        right: '7.27%',
        top: '6.57%',
        bottom: '31.24%',
        background: '#D9D9D9',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '50%'
      }}>
        
      </div>
      <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, right: 0, textAlign: 'center' }}>
        <Card.Title style={{
          fontWeight: 700,
          color: '#373232',
          marginBottom: '0.5rem'
        }}>{name}</Card.Title>
        <Card.Text style={{
        
          fontStyle: 'italic',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '22px',
          color: '#373232',
          marginBottom: '1rem'
        }}>{role}</Card.Text>
        <div className="social-icons" style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
          color: '#373232'
        }}>
          <FaFacebookF />
          <FaTwitter />
          <FaInstagram />
          <FaLinkedinIn />
        </div>
      </Card.Body>
    </Card>
  );
};

export default TeamMemberCard;
*/