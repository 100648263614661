import React from 'react';
import styled from 'styled-components';

const BlockWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const ImageLink = styled.a`
  display: block;
  height: 80px;
  width: 80px;
  margin-right: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.25rem;


`;

const TextContent = styled.div`
  flex: 1;
  color: rgba(255, 255, 255, 0.7);

`;

const Heading = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
`;

const Meta = styled.div`
  display: flex;
  font-size: 14px;
`;

const MetaItem = styled.div`
  margin-right: 10px;
  
  &:last-child {
    margin-right: 0;
  }
`;

const BlogPost = ({ imageUrl, title, date, author, comments }) => {
  return (
    <BlockWrapper className="block-21">
      <ImageLink 
        href="#" 
        className="img rounded" 
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <TextContent>
        <Heading>
          <a href="#" style={{color:"rgba(255, 255, 255, 0.7)", fontWeight:"400"}} >{title}</a>
        </Heading>
        <Meta>
          <MetaItem><a href="#" style={{color:"gray"}} >{date}</a></MetaItem>
          <MetaItem><a href="#" style={{color:"gray"}}>{author}</a></MetaItem>
          <MetaItem><a href="#" style={{color:"gray"}}>{comments}</a></MetaItem>
        </Meta>
      </TextContent>
    </BlockWrapper>
  );
};

export default BlogPost;