import React from 'react';
import styled from 'styled-components';
import AbbreviatedMonth from '../../../Components/Helpers/AbbreviatedMonth';
import DayOfMonth from '../../../Components/Helpers/DayOfMonth';

const EventListing = ({ month, day, title, href, startDate, endDate }) => {

      // Function to format the date range
  const formatDateRange = (start, end) => {
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    if (start === end) {
      return formatDate(start);
    } else {
      return `${formatDate(start)} - ${formatDate(end)}`;
    }
  };


  return (
    <EventContainer>
      <DateTag>
        <Month><AbbreviatedMonth date={month}/></Month>
        <Day><DayOfMonth date={day} /></Day>
      </DateTag>
      <EventDetails>
        <DateRange>{formatDateRange(startDate, endDate)}</DateRange>
        <EventTitle>
          <a href={href} className='truncated-text'>{title}</a>
        </EventTitle>
      </EventDetails>
    </EventContainer>
  );
};

const EventContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const DateTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  text-align: center;
`;

const Month = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: #666;
`;

const Day = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: var(--txtcolor)
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateRange = styled.div`
  text-align:left;
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  font-style: italic;
`;

const EventTitle = styled.h3`
  font-size: 18px;
  margin: 0;
  text-align: left;
  
  a {
    color: var(--txtcolor);
    text-decoration: none;
    
    &:hover {
      text-decoration: none;
      color: var(--redcolor);
    }
  }
`;

export default EventListing;