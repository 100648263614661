import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import { Link, Outlet } from 'react-router-dom';
import TransitionWrapper from './Components/Transitions/TransitionWrapper';
import { FirebaseProvider } from './Context/FirebaseContext';
import * as $ from "jquery" // ---> this line
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <div className="App bg-light">
        <HelmetProvider >
          <FirebaseProvider>
            <TransitionWrapper>
              <Outlet />
            </TransitionWrapper>
          </FirebaseProvider>
        </HelmetProvider>
    </div>
  );
}

export default App;