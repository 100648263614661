import React from 'react';
import styled from 'styled-components';

const FooterHeading = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`;

const LinkList = styled.ul`
  list-style: none;
  text-align: left;
  padding: 0;
`;

const LinkItem = styled.li`
  margin-bottom: 10px;
`;

const Link = styled.a`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 5px;
  display: inline-block;
  width: 100%;
  transition: color 0.3s ease;

  &:hover {
    color: var(--redcolor);
  }
`;

const QuickLinks = ({ links, title = "Quick Links" }) => {
  return (
    <div>
      <LinkList>
        {links.map((link, index) => (
          <LinkItem key={index}>
            <Link href={link.href}>{link.text}</Link>
          </LinkItem>
        ))}
      </LinkList>
    </div>
  );
};

export default QuickLinks;