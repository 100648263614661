import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import DateFormater from '../../../Components/Helpers/DateFormater';
import FetchNameById from '../../../Components/Helpers/FetchNameById';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import AbbreviatedMonth from '../../../Components/Helpers/AbbreviatedMonth';
import DayOfMonth from '../../../Components/Helpers/DayOfMonth';
import './Activity.css';
import Modal from 'react-bootstrap/Modal';

const ServiceCard = styled.div`
  text-align:left
  padding: 30px;
  margin: 20px auto;
`;

const SessionCard = styled.div`
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
`;

const DateSection = styled.div`
  flex: 3;
  padding: 15px;
  background-color: #f5f5f5;
`;

const ContentSection = styled.div`
  flex: 9;
  padding: 15px;
`;

const SessionButton = styled.button`
  background-color: var(--redcolor);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
`;



const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;

  @media (min-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`;

const Box = styled.div`
  background-color: #fff;
`;

const Title = styled.h3`
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const Date = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const TimingSection = styled.div`
  background-color: #E6E6FA;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    padding: 10px;
  }
`;

const Timing = styled.p`
  margin: 5px 0;
  font-size: 16px;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--bodybgcolorgrey);
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const SectionContent = styled.p`
  margin: 5px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const BibleReading = styled(SectionContent)`
  font-style: italic;
`;

const MemoryVerse = styled(SectionContent)`
  font-weight: bold;
  margin-top: 15px;
  font-size: 18px;
  color: var(--redcolor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const VerseSection = styled.p`
  margin: 5px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const verse = styled(SectionContent)`
  font-weight: bold;
  margin-top: 15px;
  font-size: 18px;
  color: var(--redcolor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const DateTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  text-align: center;
`;

const Month = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: #666;
`;

const Day = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: var(--txtcolor)
`;

const responsive = {
  desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  }
};


const UpcomingService = () => {
  const { db } = useFirebase();
  const [serviceData, setServiceData] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

 

  useEffect(() => {
    const fetchUpcomingService = async () => {
      const today = new window.Date();
      today.setHours(0, 0, 0, 0);
      const todayString = today.toISOString().split('T')[0];
      
      const servicesRef = collection(db, 'services');
      const q = query(
        servicesRef,
        where('date', '>=', todayString),
        orderBy('date'),
        limit(1)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setServiceData(doc.data());
        }
      } catch (error) {
        console.error('Error fetching upcoming service:', error);
      }
    };

    fetchUpcomingService();
  }, [db]);

  if (!serviceData) {
    return <ServiceCard>
      <p style={{textAlign:"left"}}>Worship with us! Join our vibrant community for inspiring services, filled with uplifting music, relevant teachings, and authentic connection. Our services are designed to help you:
    Encounter God's love and presence
    Grow in faith and spiritual depth
    Find hope and support</p>
    </ServiceCard>;
  }


  const handleSessionClick = (session) => {
    setSelectedSession(session);
    handleShow('md-down');
  };

  const closeModal = () => {
    setSelectedSession(null);
  };

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <Container>
      
      <div>
        <Row>
          <Col xs={2} sm={1}>
          <DateTag>
            <Month><AbbreviatedMonth date={serviceData.date}/></Month>
            <Day><DayOfMonth date={serviceData.date} /></Day>
          </DateTag>
          
          </Col>
          <Col xs={10} sm={11}>
          
            <Title style={{textAlign:'left'}}>{serviceData.theme}</Title>
            <p style={{textAlign:'left',fontStyle:'italic', fontWeight:"600"}}>Service Memory Verse: {serviceData.memVerse}</p>
            <p style={{textAlign:'left', fontSize:"16px"}}>Session Activity Details</p>
            <ul className='custom-list' >
            {serviceData.sessions.map((session, index) => (
              <li key={index}>
                <Button onClick={() => handleSessionClick(session)} variant='link' 
                style={{textTransform:'none', 
                        textDecoration:'none', 
                        textAlign:"left",
                        color:'var(--bluecolor)'
                        }}
                        >
                    Session Details for {session.startTime} 
                  </Button> 
              </li>
              
            ))}
          </ul>
          </Col>
        </Row>
      </div>
  
      

      

      {selectedSession && (
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Service Details</Modal.Title>
          </Modal.Header>
         
          <Modal.Body>
            <Box>
              <TimingSection>
                <Timing><strong>Start: </strong>{selectedSession.startTime}</Timing>
                <Timing><strong>Close: </strong>{selectedSession.endTime}</Timing>
              </TimingSection>

              <Section>
                <SectionTitle>LITURGY</SectionTitle>
                <SectionContent>{selectedSession.liturgy}</SectionContent>
              </Section>

              {selectedSession.activities.map((activity, index) => (
                <Section key={index}>
                  <SectionTitle>{activity.name}</SectionTitle>
                  <SectionContent>
                    <FetchNameById 
                      collectionName={'members'} 
                      documentId={activity.leaderId}
                    />
                  </SectionContent>
                  <SectionContent>{activity.description}</SectionContent>
                </Section>
              ))}

              <MemoryVerse>Memory Verse</MemoryVerse>
              <VerseSection>{serviceData.memVerse}</VerseSection>
            </Box>
            </Modal.Body>
          
        </Modal>
      )}
    </Container>
  );
};

export default UpcomingService;

/*
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import DateFormater from '../../../Components/Helpers/DateFormater';
import FetchNameById from '../../../Components/Helpers/FetchNameById';


const ServiceCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 500px;
  margin: 20px auto;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const Box = styled.div`
  background-color: #fff;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--redcolor);
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

const Date = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const TimingSection = styled.div`
  background-color: #E6E6FA;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    padding: 10px;
  }
`;

const Timing = styled.p`
  margin: 5px 0;
  font-size: 16px;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--bodybgcolorgrey);
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const SectionContent = styled.p`
  margin: 5px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const BibleReading = styled(SectionContent)`
  font-style: italic;
`;

const MemoryVerse = styled(SectionContent)`
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: var(--redcolor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const VerseSection = styled.p`
  margin: 5px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const verse = styled(SectionContent)`
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: var(--redcolor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;
const responsive = {
  desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  }
};

const UpcomingService = () => {

  const { db } = useFirebase();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchUpcomingService = async () => {
      const today = new window.Date();
      today.setHours(0, 0, 0, 0);
      const todayString = today.toISOString().split('T')[0];
      //console.log(today);
      const servicesRef = collection(db, 'services');
      const q = query(
        servicesRef,
        where('date', '>=', todayString),
        orderBy('date'),
        limit(1)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          //console.log(doc.data());
          setServiceData(doc.data());
        } else {
          console.log('No upcoming services found');
        }
      } catch (error) {
        console.error('Error fetching upcoming service:', error);
      }
    };

    fetchUpcomingService();
  }, [db]);

  if (!serviceData) {
    return <ServiceCard><Title>Loading upcoming service...</Title></ServiceCard>;
  }


  return (
    <ServiceCard>
      <Title>Upcoming Service</Title>
      <Date>{<DateFormater date={serviceData.date} />}</Date>

      <Section>
        <SectionTitle>THEME</SectionTitle>
        <SectionContent >{serviceData.theme}</SectionContent>
      </Section>

      <Carousel 
        responsive={responsive}
        swipeable={true}
        draggable={false}
        infinite={true}
        autoPlay={false}
      >
        {serviceData.sessions.map((item, index)=>(
          <Box key={index}>
            <TimingSection>
              <Timing><strong>Start: </strong>{item.startTime}</Timing>
              <Timing><strong>Close: </strong> {item.endTime}</Timing>
            </TimingSection>

            <Section>
              <SectionTitle>LITURGY</SectionTitle>
              <SectionContent>{item.liturgy}</SectionContent>
            </Section>

            {item.activities.map((activity, index)=>(
              <Section key={index}>
                <SectionTitle>{activity.name}</SectionTitle>
                <SectionContent><FetchNameById collectionName={'members'} documentId={activity.leaderId}/></SectionContent>
                <SectionContent>{activity.description}</SectionContent>
              </Section>
            ))}

            
            <MemoryVerse>Memory Verse</MemoryVerse>
            <VerseSection>{serviceData.memVerse}</VerseSection>
          </Box>
        ))}
      </Carousel>
    </ServiceCard>
  );
};



export default UpcomingService;
*/