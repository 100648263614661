import React from 'react';
import { FaFacebookF, FaXTwitter, FaSquareInstagram } from "react-icons/fa6";

const SocialIcons = () => {
  const iconStyle = {
    color: 'white',
    fontSize: '20px', // Slightly reduced size to fit well in the circle
  };

  const listStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  };

  const listItemStyle = {
    marginRight: '10px', // This creates the 10px space between icons
  };

  const anchorStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: "var(--redcolor)",
    textDecoration: 'none',
  };

  return (
    <ul style={listStyle}>
      <li style={listItemStyle}>
        <a href="https://www.facebook.com/emmanuelcongregationkasoa?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" style={anchorStyle}>
          <FaFacebookF style={iconStyle} />
        </a>
      </li>
      <li style={listItemStyle}>
        <a href="https://x.com/PCG_Emma_Kasoa?t=AAmndgdSfcra-fHdAPiAyw&s=09" target="_blank" rel="noopener noreferrer" style={anchorStyle}>
          <FaXTwitter style={iconStyle} />
        </a>
      </li>
      <li style={listItemStyle}>
        <a href="https://www.instagram.com/pcg.emmanuelcongregationkasoa?igsh=YjN4dmgzdXFuajNq" target="_blank" rel="noopener noreferrer" style={anchorStyle}>
          <FaSquareInstagram style={iconStyle} />
        </a>
      </li>
    </ul>
  );
};

export default SocialIcons;