import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PageHeader.css';  // We'll create this CSS file

const PageHeader = ({ title, backgroundImage }) => {
  return (
    <div 
      className="header-container"
      style={{ '--background-image': `url(${backgroundImage})` }}
    >
      <div className="overlay"></div>
      <div className="gradient-overlay"></div>
      <Container fluid className="h-100">
        <Row className="h-100 align-items-center">
          <Col xs={12} md={8} lg={6}>
            <h1 className="header-text">{title}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageHeader;