import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Spinner, Button, Modal } from 'react-bootstrap';
import { collection, query, where, getDocs, orderBy,limit } from 'firebase/firestore';
import { useFirebase } from '../../Context/FirebaseContext';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const PastorsComponent = () => {
  const [currentMinister, setCurrentMinister] = useState(null);
  const [pastMinisters, setPastMinisters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { db } = useFirebase();
  const profileRef = useRef(null);

  useEffect(() => {
    const fetchCurrentMinister = async () => {
      try {
        const currentMinisterQuery = query(collection(db, 'currentMinister'), limit(1));
        const currentMinisterSnapshot = await getDocs(currentMinisterQuery);
        
        if (currentMinisterSnapshot.empty) {
          setError('No current minister found');
          setLoading(false);
          return;
        }

        const currentMinisterData = currentMinisterSnapshot.docs[0].data();
        const ministerId = currentMinisterData.ministerId;

        const ministerQuery = query(collection(db, 'ministers'), where('id', '==', ministerId));
        const ministerSnapshot = await getDocs(ministerQuery);

        if (ministerSnapshot.empty) {
          setError('Minister details not found');
          setLoading(false);
          return;
        }

        setCurrentMinister(ministerSnapshot.docs[0].data());
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch minister data');
        setLoading(false);
      }
    };

    fetchCurrentMinister();
  }, [db]);

  const fetchPastMinisters = async () => {
    try {
      const ministersQuery = query(
        collection(db, 'ministers'),
        orderBy('endTenure', 'desc')
      );
      const ministersSnapshot = await getDocs(ministersQuery);
      
      const ministers = ministersSnapshot.docs
        .map(doc => doc.data())
        .filter(minister => minister.endTenure); // Only include ministers with an end tenure

      setPastMinisters(ministers);
    } catch (err) {
      console.error('Error fetching past ministers:', err);
      setError('Failed to fetch past ministers');
    }
  };

  const handleShowModal = async () => {
    if (pastMinisters.length === 0) {
      await fetchPastMinisters();
    }
    setShowModal(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '577px' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '577px' }}>
        <p>{error}</p>
      </Container>
    );
  }

  return (
    <>
      <div style={{ margin: "50px 5%", overflow: "hidden" }}>
        <Row className="h-100" style={{ textAlign: "left" }}>
          <Col sm={7} className="d-flex flex-column">
            <h2 style={{ marginBottom: "40px" }}>
              RESIDENT MINISTER
            </h2>
            
            <h3>
              {currentMinister.name}
            </h3>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '80px',
              marginBottom: '20px'
            }}>
              <FaFacebookF size={14} />
              <FaTwitter size={14} />
              <FaInstagram size={14} />
              <FaLinkedinIn size={14} />
            </div>
            
            <p style={{
              lineHeight: '22px',
              color: '#000000',
              maxHeight: expanded ? 'none' : '88px',
              overflow: 'hidden'
            }} ref={profileRef}>
              {expanded ? currentMinister.profile : truncateText(currentMinister.profile, 300)}
            </p>
            
            <span 
              style={{
                fontStyle: 'italic',
                fontSize: '12px',
                color: '#FA0523',
                marginBottom: '20px',
                cursor: 'pointer'
              }}
              onClick={toggleExpand}
            >
              {expanded ? 'Read less' : 'Read more'}
            </span>
            
            <Button
              onClick={handleShowModal}
              className="past-ministers-btn"
              style={{
                padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
                fontSize: 'clamp(14px, 1vw, 18px)',
                backgroundColor: 'transparent',
                color: 'var(--txtcolor)',
                border: '2px solid var(--redcolor)',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: 600,
                transition: 'all 0.3s ease',
                width: '100%',
                '@media (min-width: 576px)': {
                  width: '150px'
                }
              }}
            >
              Past Ministers
            </Button>
          </Col>
          
          <Col sm={5}>
            <div>
              <img src={currentMinister.profilePicUrl} alt="resident minister" height="auto" width="100%" style={{maxWidth:'400px'}} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)} 
        size="lg"
        dialogClassName="past-ministers-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '1.25rem' }}>Past Ministers</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <Row className="mx-0">
            {pastMinisters.map((minister, index) => (
              <Col xs={12} key={minister.id} className="px-2">
                <div 
                  className="d-flex align-items-center py-3" 
                  style={{ 
                    borderBottom: index !== pastMinisters.length - 1 ? '1px solid #dee2e6' : 'none'
                  }}
                >
                  <div 
                    className="minister-image-container" 
                    style={{ 
                      width: '80px',
                      height: '80px',
                      flexShrink: 0,
                      marginRight: '15px',
                      position: 'relative',
                      overflow: 'hidden',
                      borderRadius: '4px'
                    }}
                  >
                    <img 
                      src={minister.profilePicUrl} 
                      alt={minister.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  
                  <div className="minister-info flex-grow-1">
                    <h5 
                      className="mb-1" 
                      style={{ 
                        fontSize: '1rem',
                        fontWeight: '600' 
                      }}
                    >
                      {minister.name}
                    </h5>
                    
                    <div 
                      className="tenure-period mb-2" 
                      style={{ 
                        fontSize: '0.875rem',
                        color: '#666' 
                      }}
                    >
                      {new Date(minister.startTenure).getFullYear()} - {new Date(minister.endTenure).getFullYear()}
                    </div>
                    
                    <div 
                      className="social-links" 
                      style={{ 
                        display: 'flex', 
                        gap: '12px',
                        fontSize: '0.875rem' 
                      }}
                    >
                      {minister.facebook && (
                        <a 
                          href={minister.facebook} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          style={{ color: '#666' }}
                        >
                          <FaFacebookF size={12} />
                        </a>
                      )}
                      {minister.twitter && (
                        <a 
                          href={minister.twitter} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          style={{ color: '#666' }}
                        >
                          <FaTwitter size={12} />
                        </a>
                      )}
                      {minister.instagram && (
                        <a 
                          href={minister.instagram} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          style={{ color: '#666' }}
                        >
                          <FaInstagram size={12} />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            size="sm"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style>
        {`
          @media (max-width: 575px) {
            .past-ministers-btn {
              width: 100% !important;
            }
          }
          @media (min-width: 576px) {
            .past-ministers-btn {
              width: 200px !important;
            }
          }
          
          .past-ministers-modal .modal-body::-webkit-scrollbar {
            width: 6px;
          }
          
          .past-ministers-modal .modal-body::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
          }
          
          .past-ministers-modal .modal-body::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 3px;
          }
          
          .past-ministers-modal .modal-body::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          
          .minister-info a:hover {
            color: var(--redcolor) !important;
            transition: color 0.2s ease;
          }
        `}
      </style>
    </>
  );
};

export default PastorsComponent;