import React, { useState, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 100;
`;

const Bar = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${({ $open }) => ($open ? 'var(--redcolor)' : 'whitesmoke')};
  transition: all 0.3s ease-in-out;

  ${({ $open }) =>
    $open &&
    css`
      &:nth-child(1) {
        transform: translateY(10px) rotate(45deg);
        z-index: 52;
      }
      &:nth-child(2) {
        opacity: 1;
        transform: translateY(-5px) rotate(-45deg);
        z-index: 52;
      }
      &:nth-child(3) {
        transform: translateY(-10px) rotate(-45deg);
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        transition: all 0.5s ease-in-out;
      }
    `}
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
  transform: ${({ $open }) => ($open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 51;

  /* Webkit scrollbar styles */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2.5px;
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1);
`;

const MenuItem = styled.div`
  width: 100%;
  color: white;
  font-size: 24px;
  text-decoration: none;
  margin: 20px 0;
  cursor: pointer;
  opacity: 0;
  animation: ${({ $open }) => $open && css`${slideIn} 0.5s forwards`};
  animation-delay: ${({ $index }) => `${$index * 0.1}s`};

  &:hover {
    color: #ff6347;
  }
`;

const SubMenu = styled.div`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${slideDown} 0.3s ease-in-out;
`;

const SubMenuItem = styled.a`
  display: block;
  color: white;
  font-size: 20px;
  text-decoration: none;
  padding: 10px 20px;
  &:hover {
    color: #ff6347;
  }
`;

const MobileNav = ({ menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState({});
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = (index) => {
    setSubMenuOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <>
      <Container onClick={toggleMenu}>
        <Bar $open={isOpen} />
        <Bar $open={isOpen} />
        <Bar $open={isOpen} />
      </Container>
      <Menu $open={isOpen} ref={menuRef}>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem
              as={item.href ? 'a' : 'div'}
              href={item.href}
              onClick={() => item.children ? toggleSubMenu(index) : setIsOpen(false)}
              $open={isOpen}
              $index={index}
            >
              {item.label}
            </MenuItem>
            {item.children && (
              <SubMenu $open={subMenuOpen[index]}>
                {item.children.map((subItem, subIndex) => (
                  <SubMenuItem
                    key={subIndex}
                    href={subItem.href}
                    onClick={() => setIsOpen(false)}
                  >
                    {subItem.label}
                  </SubMenuItem>
                ))}
              </SubMenu>
            )}
          </React.Fragment>
        ))}
      </Menu>
    </>
  );
};

MobileNav.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default MobileNav;