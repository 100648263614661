import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, getDocs } from 'firebase/firestore';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import Footer from '../Components/Footer/Footer';
import HeaderImage from "../images/headers/s2.jpg";
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }

  &:hover img {
    transform: scale(1.05);
  }

  h5 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const Thumbnail = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
  border: ${props => props.active ? '2px solid #fff' : 'none'};
  opacity: ${props => props.active ? 1 : 0.6};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const Gallery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { db } = useFirebase();
    const [albums, setAlbums] = useState([]);
    const [currentAlbum, setCurrentAlbum] = useState(null);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAlbums = async () => {
            try {
                const albumsRef = collection(db, 'albums');
                const albumsSnapshot = await getDocs(albumsRef);
                const albumsData = albumsSnapshot.docs.map((doc) => doc.data());
                setAlbums(albumsData);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchAlbums();
    }, [db]);

    const handleAlbumClick = (album) => {
        setCurrentAlbum(album);
        setPhotoIndex(0);
        setIsOpen(true);
    };

    return (
        <>
            <SEO
                title="Gallery"
                description="View our photo gallery"
                name='Emmanuel Presbyterian Church.'
                type='gallery' 
            />
            <NavBarComponent />
            <ClearHeader 
                title='Image Gallery'
                breadcrumbs={['Home', 'Audio']} 
            />
            <div style={{marginBottom:"100px"}}></div>

            <Container className='alignleft text-dark nobg'>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : error ? (
                    <div className="alert alert-danger">Error: {error.message}</div>
                ) : albums.length === 0 ? (
                    <div className="alert alert-info">No albums found.</div>
                ) : (
                    <Row>
                        {albums.map((album, index) => (
                            <Col key={index} lg={3} md={6} className="mb-4">
                                <GalleryItem onClick={() => handleAlbumClick(album)}>
                                    <img src={album.coverImageUrl} alt={album.title} />
                                    <h5>{album.title}</h5>
                                </GalleryItem>
                            </Col>
                        ))}
                    </Row>
                )}

                {isOpen && currentAlbum && (
                    <Lightbox
                        mainSrc={currentAlbum.images[photoIndex].fullSizeUrl}
                        nextSrc={currentAlbum.images[(photoIndex + 1) % currentAlbum.images.length].fullSizeUrl}
                        prevSrc={currentAlbum.images[(photoIndex + currentAlbum.images.length - 1) % currentAlbum.images.length].fullSizeUrl}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + currentAlbum.images.length - 1) % currentAlbum.images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % currentAlbum.images.length)
                        }
                        imageTitle={currentAlbum.title}
                        imageCaption={`Image ${photoIndex + 1} of ${currentAlbum.images.length}`}
                        toolbarButtons={[
                            <ThumbnailContainer key="thumbnails">
                                {currentAlbum.images.map((image, index) => (
                                    <Thumbnail
                                        key={index}
                                        src={image.thumbnailUrl}
                                        alt={`Thumbnail ${index}`}
                                        active={index === photoIndex}
                                        onClick={() => setPhotoIndex(index)}
                                    />
                                ))}
                            </ThumbnailContainer>
                        ]}
                    />
                )}
            </Container>
            <div style={{marginTop:"20px",marginBottom:"50px"}}></div>
            <Footer />
        </>
    );
};

export default Gallery;