import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const EventsCarousel = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { db } = useFirebase();

  const fetchUpcomingEvents = async () => {
    try {
      const today = new Date();
      const dateString = today.toISOString().split('T')[0];

      const eventsCollection = collection(db, 'events');
      const eventsQuery = query(
        eventsCollection,
        where('status', '==', 'active'),
        where('endDate', '>=', dateString),
        orderBy('endDate', 'asc'),
        limit(5)
      );
      
      const querySnapshot = await getDocs(eventsQuery);
      const events = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUpcomingEvents(events);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching upcoming events:', err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingEvents();
  }, []);

  const owlOptions = {
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 3,
        margin: 20,
      }
    },
    nav: true,
    dots: false,
    loop: true,
    stagePadding: window.innerWidth >= 768 ? 50 : 0
  };

  if (loading) return <div>Loading events...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;

  return (
    <div className="container-fluid py-5">
      <div className="row">
        <div className="col-xl-3 col-12 mb-4 mb-xl-0">
          <div className="d-flex flex-column justify-content-center h-100">
            <h2 className="mb-4">Upcoming Events</h2>
            <p className="mb-4">Don't miss out on our exciting events! Join us for unforgettable experiences and memories.</p>
            <Link to="/events" className="btn btn-primary">
              View All Events
            </Link>
          </div>
        </div>
        <div className="col-xl-9 col-12">
          <OwlCarousel className="owl-theme" {...owlOptions}>
            {upcomingEvents.map(event => (
              <div key={event.id} className="item">
                <div className="card h-100 border-0 overflow-hidden">
                  <div className="position-relative h-100">
                    <img 
                      src={event.imageUrl} 
                      className="card-img h-100 w-100 object-fit-cover" 
                      alt={event.eventName}
                      style={{ minHeight: '300px' }}
                    />
                    <div className="position-absolute bottom-0 start-0 w-100 bg-dark bg-opacity-75 text-white p-3">
                      <h5 className="card-title mb-0">{event.eventName}</h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default EventsCarousel;