import React from 'react';

const DayOfMonth = ({ date }) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();

  return (
    <span>
      {day}
    </span>
  );
};

export default DayOfMonth;