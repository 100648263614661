import React, { useState } from 'react';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { BiMap, BiEnvelope, BiPhone } from 'react-icons/bi';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import SEO from '../Components/SEO';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import { useFirebase } from '../Context/FirebaseContext';

const Contact = () => {
  const { db } = useFirebase();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Validate form
      if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
        throw new Error('Please fill in all fields');
      }

      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        throw new Error('Please enter a valid email address');
      }

      // Add to Firestore
      await addDoc(collection(db, 'contacts'), {
        ...formData,
        timestamp: serverTimestamp()
      });

      setSubmitStatus({
        show: true,
        type: 'success',
        message: 'Thank you for your message! We will get back to you soon.'
      });

      // Clear form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });
    } catch (error) {
      setSubmitStatus({
        show: true,
        type: 'danger',
        message: error.message || 'Something went wrong. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const ContactInfo = ({ icon: Icon, text }) => (
    <div className="service no-shadow align-items-center link horizontal d-flex active" style={{marginRight:"10px"}}>
      <div className="service-icon color-1 mb-4 d-flex align-items-center justify-content-center">
        <Icon size={20} />
      </div>
      <div className="service-contents" style={{paddingLeft:"5px"}}>
        <p className="mb-0">{text}</p>
      </div>
    </div>
  );

  return (
    <>
      <SEO
        title='Contact'
        description='The official website of the Emmanuel Presbyterian Church Kasoa'
        name='Emmanuel Presbyterian Church'
        type='profile'
      />
      <NavBarComponent />
      <ClearHeader
        title="We Want to Hear from You"
        breadcrumbs={['Home', 'Contact']}
      />
      <Container style={{ marginTop: "60px" }}>
        <style>{`
          .form-control {
            height: 50px;
            border-radius: 10px;
            font-family: "Inter", sans-serif;
            transition: all 0.3s ease;
          }
          .form-control:focus {
            border-color: var(--bluecolor);
            box-shadow: 0 1px 4px 0 rgba(59, 93, 80, 0.2);
          }
          .service-icon {
            width: 50px;
            height: 50px;
            min-width: 50px;
            border-radius: 10px;
            background: var(--redcolor);
            color: #ffffff;
          }
          .contact-info-hover:hover {
            transform: translateY(-5px);
            transition: transform 0.3s ease;
          }
          .btn-primary-hover-outline {
            background-color: var(--redcolor);
            color: white;
            border: none;
            transition: all 0.3s ease;
          }
          .btn-primary-hover-outline:hover {
            background-color: transparent;
            color: #3b5d50;
          }
        `}</style>

        <div className="block">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-8 pb-4">
              <div className="row mb-5">
                <div className="col-lg-4 contact-info-hover">
                  <ContactInfo
                    icon={BiMap}
                    text="P. O. Box WU 60,
                        Road of Justice,
                          Kasoa Central Region,
                          Digital Addrees:  CX-002-1976"
                  />
                </div>
                <div className="col-lg-4 contact-info-hover">
                  <ContactInfo
                    icon={BiEnvelope}
                    text="pcgemmanuelkasoa@gmail.com"
                  />
                </div>
                <div className="col-lg-4 contact-info-hover">
                  <ContactInfo
                    icon={BiPhone}
                    text="+233 24 325 0605"
                  />
                </div>
              </div>

              {submitStatus.show && submitStatus.type === 'success' ? (
                <div className="text-center py-5">
                  <div className="mb-4">
                    <div className="thankyou-icon d-inline-block p-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </div>
                  </div>
                  <h2 className="mb-3">Thank you for contacting us!</h2>
                  <p className="mb-4">We've received your message and will get back to you soon.</p>
                  <button 
                    className="btn btn-primary-hover-outline"
                    onClick={() => setSubmitStatus({ show: false, type: '', message: '' })}
                  >
                    Send Another Message
                  </button>
                </div>
              ) : (
                <Form onSubmit={handleSubmit}>
                  {submitStatus.show && (
                    <Alert variant={submitStatus.type} onClose={() => setSubmitStatus({ show: false, type: '', message: '' })} dismissible>
                      {submitStatus.message}
                    </Alert>
                  )}

                  <div className="row">
                    <div className="col-6" >
                      <Form.Group className="mb-3" style={{textAlign:"left"}}>
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          id="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          placeholder="Enter your first name"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3" style={{textAlign:"left"}}>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          id="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          placeholder="Enter your last name"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group className="mb-3" style={{textAlign:"left"}}>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter your email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" style={{textAlign:"left"}}>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      id="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      rows={5}
                      placeholder="Write your message here"
                    />
                  </Form.Group>

                  <Button 
                    type="submit" 
                    style={{
                      padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
                      fontSize: 'clamp(14px, 1vw, 18px)',
                      backgroundColor: 'transparent',
                      color: 'var(--txtcolor)',
                      border: '2px solid var(--redcolor)',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      fontWeight: 600,
                      transition: 'all 0.3s ease',
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Sending...
                      </>
                    ) : (
                      'Send Message'
                    )}
                  </Button>
                </Form>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;