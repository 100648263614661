import React from 'react';

const AbbreviatedMonth = ({ date }) => {
  const dateObject = new Date(date);
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const month = monthNames[dateObject.getMonth()];

  return month;
};

export default AbbreviatedMonth;