import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlay, FaPause, FaForward, FaBackward, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { useFirebase } from '../../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import './Audio.css';

const AudioPlayerComponent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const [audioSrc, setAudioSrc] = useState('');
  const { db } = useFirebase();

  const audioRef = useRef(null);

  useEffect(() => {
    const fetchCurrentAudio = async () => {
      try {
        const currentAudioDoc = await getDoc(doc(db, 'currentAudio', 'current'));
        if (currentAudioDoc.exists()) {
          const audioFileId = currentAudioDoc.data().audioFileId;
          const audioFileDoc = await getDoc(doc(db, 'audioFiles', audioFileId));
          if (audioFileDoc.exists()) {
            setAudioSrc(audioFileDoc.data().downloadURL);
          }
        }
      } catch (error) {
        console.error("Error fetching audio file:", error);
      }
    };

    fetchCurrentAudio();
  }, [db]);

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);
    const handleBuffering = () => setBuffering(true);
    const handleCanPlay = () => setBuffering(false);

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadedmetadata', updateDuration);
    audio.addEventListener('waiting', handleBuffering);
    audio.addEventListener('canplay', handleCanPlay);

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
      audio.removeEventListener('waiting', handleBuffering);
      audio.removeEventListener('canplay', handleCanPlay);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch(() => setBuffering(true));
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleForward = () => {
    const audio = audioRef.current;
    audio.currentTime += 10;
  };

  const handleRewind = () => {
    const audio = audioRef.current;
    audio.currentTime -= 10;
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    const audio = audioRef.current;
    audio.volume = newVolume;
    setVolume(newVolume);
  };

  const handleSeekChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <PlayerContainer>
      <audio ref={audioRef} src={audioSrc} preload="metadata"></audio>
      <Controls>
        <Button onClick={handleRewind} className='control-size'><FaBackward /></Button>
        <Button onClick={togglePlayPause} className='control-size'>{isPlaying ? <FaPause /> : <FaPlay />}</Button>
        <Button onClick={handleForward} className='control-size'><FaForward /></Button>
        <Button onClick={toggleMute} className='control-size'>{isMuted ? <FaVolumeMute /> : <FaVolumeUp />}</Button>
      </Controls>
      <SeekVolumeContainer>
        <SeekBar>
          <StyledRangeInput
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeekChange}
           
          />
          <Time>{formatTime(currentTime)} / {formatTime(duration - currentTime)}</Time>
        </SeekBar>
        <VolumeControl>
          <VolumeRangeInput
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
        </VolumeControl>
      </SeekVolumeContainer>
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  color: #fff;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 2%;
  margin-top: 5px;
`;

const SeekVolumeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: var(--txtcolorwhite);
  font-size: 25px;
  cursor: pointer;
  &:hover {
    color: var(--redcolor);
    transform: scale(1.5);
    transition: transform 0.3s;
  }
`;

const SeekBar = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const Time = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  margin-top: 5px;
`;

const VolumeControl = styled.div`
  display: flex;
  align-items: center;
  width: 15%;
  margin-top: 12px !important;
`;

const StyledRangeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: ${props => props.buffering 
    ? 'linear-gradient(to right, var(--redcolor) 30%, #ccc 30%)' 
    : '#ccc'};
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--redcolor);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: var(--redcolor);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const VolumeRangeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(
    to right,
    var(--redcolor) 0%,
    var(--redcolor) ${props => props.value * 100}%,
    #ccc ${props => props.value * 100}%,
    #ccc 100%
  );
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--redcolor);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: var(--redcolor);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

export default AudioPlayerComponent;