import React, {  useEffect, } from 'react';
import { useSpring, animated } from 'react-spring';

// Parallax Header Component
const ParallaxHeader = ({ title, backgroundImage }) => {
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    set({ offset: scrollTop });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
//WAS 50vh CHANGED 460px
  return (
    <div style={{ height: '460px', position: 'relative', overflow: 'hidden' }}>
      <animated.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: offset.interpolate(o => `translateY(${o * 0.5}px)`),
        }}
      />
      <animated.div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: offset.interpolate(o => `translate(-50%, -${50 + o * 0.2}%)`),
          textAlign: 'center',
          color: 'white',
          fontSize: '3rem',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        }}
      >
        {title}
      </animated.div>
    </div>
  );
};

export default ParallaxHeader;

/*
<ParallaxHeader 
  title="Events" 
  backgroundImage={HeaderImage}
/>
*/