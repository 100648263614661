// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXpVoNb2yyhNoVVKCA9EJoKyFlEPA_BaA",
  authDomain: "presby-af279.firebaseapp.com",
  projectId: "presby-af279",
  storageBucket: "presby-af279.appspot.com",
  messagingSenderId: "713940432582",
  appId: "1:713940432582:web:ec255b6efded11a9c109c7",
  measurementId: "G-EHF5C05V7N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// const analytics = getAnalytics(app);
const db = getFirestore(app);
export const auth = getAuth(app);
const storage = getStorage(app);

export { db, app , storage };