import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, Container, Row, Col, Button, Badge, ProgressBar, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import {
  BiBuildingHouse,
  BiAward,
  BiWorld,
  BiRocket,
  BiMedal,
  BiTrophy,
  BiTargetLock,
  BiGift,
  BiChevronRight,
  BiChevronLeft,
  BiPlus,
  BiMinus,
  BiPlayCircle,
  BiPauseCircle,
  BiFullscreen,
} from 'react-icons/bi';
import { RiFullscreenExitFill } from "react-icons/ri";
import { FaHandshake } from "react-icons/fa";
import { collection, query, orderBy, getDocs, limit } from 'firebase/firestore';
import { useFirebase } from '../../Context/FirebaseContext';

const styles = `
  .timeline-wrapper {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --info-color: #17a2b8;
    --warning-color: #ffc107;
    --danger-color: #dc3545;
    --light-color: #f8f9fa;
    --dark-color: #343a40;
  }

  .timeline-card {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .timeline-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .icon-container {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container:hover {
    transform: rotate(12deg) scale(1.1);
  }
  
  .timeline-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    opacity: 0.6;
  }
  
  .timeline-dot.active {
    width: 16px;
    height: 16px;
    opacity: 1;
  }
  
  .year-label {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .year-label:hover {
    transform: scale(1.1);
    background-color: var(--light-color);
  }
  
  .year-label.active {
    color: var(--primary-color);
    font-weight: bold;
    background-color: var(--light-color);
  }
  
  .progress-animated {
    transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    height: 6px;
  }
  
  .type-badge {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
  }
  
  .type-badge:hover {
    transform: scale(1.1);
  }

  .stats-card {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .stats-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .timeline-controls {
    position: sticky;
    top: 75px;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    padding: 1rem 0;
  }

  .fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1050;
    overflow-y: auto;
    padding: 2rem;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .fade-in {
    animation: fadeIn 0.3s ease-in-out;
  }
`;

// Icon mapping with default icon fallback
const iconMapping = {
  BiBuildingHouse,
  BiAward,
  BiWorld,
  BiRocket,
  BiMedal,
  BiTrophy,
  BiTargetLock,
  BiHandshake: FaHandshake,
  BiGift,
};

const DEFAULT_ICON = BiBuildingHouse;
const AUTOPLAY_INTERVAL = 3000;
const ITEMS_PER_PAGE = 10;

// Type variants mapping
const TYPE_VARIANTS = {
  milestone: 'primary',
  achievement: 'success',
  expansion: 'info',
  initiative: 'warning',
  default: 'secondary'
};

const Timeline = ({ initialFilter = 'all' }) => {
  const { db } = useFirebase();
  const [timelineData, setTimelineData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterType, setFilterType] = useState(initialFilter);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [page, setPage] = useState(1);

  // Memoized filtered data
  const filteredData = useMemo(() => {
    const filtered = filterType === 'all'
      ? timelineData
      : timelineData.filter(item => item.type === filterType);
    
    return filtered.slice(0, page * ITEMS_PER_PAGE);
  }, [timelineData, filterType, page]);

  // Current item with safety check
  const currentItem = useMemo(() => {
    return filteredData[activeIndex] || {};
  }, [filteredData, activeIndex]);

  // Fetch timeline data
  useEffect(() => {
    let isMounted = true;

    const fetchTimelineData = async () => {
      setLoading(true);
      try {
        const timelineRef = collection(db, 'timeline');
        const timelineQuery = query(
          timelineRef,
          orderBy('year', 'asc')
        );
        
        const querySnapshot = await getDocs(timelineQuery);
        
        if (!isMounted) return;

        const data = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          icon: React.createElement(
            iconMapping[doc.data().iconName] || DEFAULT_ICON,
            { size: 24 }
          )
        }));
        
        setTimelineData(data);
        setError(null);
      } catch (err) {
        if (!isMounted) return;
        console.error('Error fetching timeline data:', err);
        setError(err.message || 'Failed to load timeline data. Please try again later.');
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchTimelineData();

    return () => {
      isMounted = false;
    };
  }, [db]);

  // Autoplay effect
  useEffect(() => {
    let interval;
    if (isPlaying && filteredData.length > 0) {
      interval = setInterval(() => {
        setActiveIndex(prev => 
          prev === filteredData.length - 1 ? 0 : prev + 1
        );
      }, AUTOPLAY_INTERVAL);
    }
    return () => clearInterval(interval);
  }, [isPlaying, filteredData.length]);

  // Navigation handlers
  const nextSlide = useCallback(() => {
    setActiveIndex(prev => 
      prev === filteredData.length - 1 ? 0 : prev + 1
    );
  }, [filteredData.length]);

  const prevSlide = useCallback(() => {
    setActiveIndex(prev => 
      prev === 0 ? filteredData.length - 1 : prev - 1
    );
  }, [filteredData.length]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight') nextSlide();
      if (e.key === 'ArrowLeft') prevSlide();
      if (e.key === 'Escape' && isFullscreen) setIsFullscreen(false);
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [nextSlide, prevSlide, isFullscreen]);

  // Loading state
  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading timeline data...</span>
        </Spinner>
      </Container>
    );
  }

  // Error state
  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger" dismissible>
          <Alert.Heading>Error Loading Timeline</Alert.Heading>
          <p>{error}</p>
          <Button 
            variant="outline-danger" 
            onClick={() => window.location.reload()}
          >
            Retry
          </Button>
        </Alert>
      </Container>
    );
  }

  // Empty state
  if (timelineData.length === 0) {
    return (
      <Container className="py-5">
        <Alert variant="info">
          <Alert.Heading>No Timeline Events</Alert.Heading>
          <p>There are no events to display at this time.</p>
        </Alert>
      </Container>
    );
  }

  const MainContent = () => (
    <>
      <div className="timeline-controls mb-4">
        <Row className="align-items-center">
          <Col>
            <div className="d-flex gap-2">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setIsPlaying(!isPlaying)}
                title={isPlaying ? 'Pause' : 'Play'}
              >
                {isPlaying ? <BiPauseCircle size={20} /> : <BiPlayCircle size={20} />}
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setIsExpanded(!isExpanded)}
                title={isExpanded ? 'Show Less' : 'Show More'}
              >
                {isExpanded ? <BiMinus size={20} /> : <BiPlus size={20} />}
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setIsFullscreen(!isFullscreen)}
                title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
              >
                {isFullscreen ? <RiFullscreenExitFill  size={20} /> : <BiFullscreen size={20} />}
              </Button>
            </div>
          </Col>
          
          <Col xs="auto">
            <div className="d-flex gap-2 flex-wrap">
              <Badge 
                bg={filterType === 'all' ? 'primary' : 'secondary'}
                className="type-badge p-2"
                onClick={() => {
                  setFilterType('all');
                  setActiveIndex(0);
                }}
                style={{ cursor: 'pointer' }}
              >
                All
              </Badge>
              {Object.keys(TYPE_VARIANTS).map((type) => (
                <Badge
                  key={type}
                  bg={filterType === type ? TYPE_VARIANTS[type] : 'secondary'}
                  className="type-badge p-2 text-capitalize"
                  onClick={() => {
                    setFilterType(type);
                    setActiveIndex(0);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {type}
                </Badge>
              ))}
            </div>
          </Col>
        </Row>
      </div>

      <Row className="mb-4">
        <Col className="d-flex justify-content-center align-items-center">
          <Button 
            variant="link" 
            onClick={prevSlide}
            className="text-decoration-none"
          >
            <BiChevronLeft size={24} />
          </Button>
          
          <div className="d-flex gap-2 mx-3">
            {filteredData.map((_, index) => (
              <div
                key={index}
                className={`timeline-dot bg-${
                  index === activeIndex ? 'primary' : 'secondary'
                } ${index === activeIndex ? 'active' : ''}`}
                onClick={() => setActiveIndex(index)}
                title={`Go to event ${index + 1}`}
              />
            ))}
          </div>

          <Button 
            variant="link" 
            onClick={nextSlide}
            className="text-decoration-none"
          >
            <BiChevronRight size={24} />
          </Button>
        </Col>
      </Row>

      <Card className="timeline-card fade-in">
        <Card.Body className="p-4">
          <Row>
            <Col xs="auto">
              <div 
                className={`icon-container p-3 rounded-circle bg-${
                  TYPE_VARIANTS[currentItem.type] || 'primary'
                } text-white`}
              >
                {currentItem.icon || <DEFAULT_ICON size={24} />}
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="h2 mb-0">{currentItem.year}</h3>
                  <h4 className="h4">{currentItem.title}</h4>
                </div>
                <Badge 
                  bg={TYPE_VARIANTS[currentItem.type] || 'secondary'}
                  className="text-capitalize"
                >
                  {currentItem.type}
                </Badge>
              </div>
              <p className="mt-3" style={{textAlign:"left"}}>
                {isExpanded 
                  ? currentItem.longDescription 
                  : currentItem.description}
              </p>

              {currentItem.stats && (
                <Row className="mt-4">
                  {Object.entries(currentItem.stats).map(([key, value]) => (
                    <Col key={key} xs={6} md={3} className="mb-3">
                      <Card className="stats-card bg-light h-100">
                        <Card.Body className="p-2">
                          <div className="text-muted text-capitalize small">
                            {key.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className="fw-bold">
                            {typeof value === 'number' 
                              ? value.toLocaleString()
                              : value}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}

              {currentItem.links && (
                <div className="mt-4">
                  <h5>Related Links</h5>
                  <div className="d-flex gap-2 flex-wrap">
                    {currentItem.links.map((link, index) => (
                      <Button
                        key={index}
                        variant="outline-primary"
                        size="sm"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.title}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ProgressBar 
        now={((activeIndex + 1) / filteredData.length) * 100}
        className="mt-4 progress-animated"
        variant={TYPE_VARIANTS[currentItem.type] || 'primary'}
      />

      <div className="mt-4 position-relative">
        <div className="d-flex justify-content-between overflow-x-auto pb-2">
          {filteredData.map((item, index) => (
            <span
              key={index}
              className={`year-label ${index === activeIndex ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)}
              title={`Go to ${item.year}`}
            >
              {item.year}
            </span>
          ))}
        </div>
      </div>

      {filteredData.length < timelineData.length && (
        <div className="text-center mt-4">
          <Button
            variant="outline-primary"
            onClick={() => setPage(prev => prev + 1)}
          >
            Load More Events
          </Button>
        </div>
      )}
    </>
  );

  return (
    <div className="timeline-wrapper">
      <style>{styles}</style>
      {isFullscreen ? (
        <div className="fullscreen-container">
          <Container>
            <MainContent />
          </Container>
        </div>
      ) : (
        <Container className="py-5">
          <MainContent />
        </Container>
      )}
    </div>
  );
};

Timeline.propTypes = {
  initialFilter: PropTypes.oneOf(['all', 'milestone', 'achievement', 'expansion', 'initiative'])
};

export default Timeline;