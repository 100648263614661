import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { format } from 'date-fns';
import DateRangePicker from './DateRangePicker';
import NowDateRangePicker from './NowDateRangePicker';
import './Events.css';

const EventList = () => {
    const { db } = useFirebase();
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isToday, setIsToday] = useState(false);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const handleEventClick = (eventname, eventId) => {
        navigate(`/events/${createSlug(eventname)}/${eventId}`);
    };

    const  createSlug = (string) => {
        string = string.toLowerCase();
        string = string.replace(/[^a-z0-9 ]/g, '');
        string = string.replace(/\s+/g, '-');
        string = string.replace(/^-|-$/g, '');
        if (string === '') {
          return 'slug'; 
        }
      
        return string;
    }  

    const fetchEvents = async (start = null, end = null, isToday = false, loadMore = false) => {
        setLoading(true);
        try {
            let q;
            if (isToday) {
                const today = format(new Date(), 'yyyy-MM-dd');
                q = query(
                    collection(db, 'events'),
                    where('startDate', '==', today),
                    where('deleted', '==', false),
                    where('status', '==', 'active'),
                    orderBy('startDate'),
                    limit(3)
                );
            } else if (start && end) {
                q = query(
                    collection(db, 'events'),
                    where('startDate', '>=', format(start, 'yyyy-MM-dd')),
                    where('startDate', '<=', format(end, 'yyyy-MM-dd')),
                    where('deleted', '==', false),
                    where('status', '==', 'active'),
                    orderBy('startDate'),
                    limit(3)
                );
            } else {
                q = query(
                    collection(db, 'events'),
                    where('deleted', '==', false),
                    where('status', '==', 'active'),
                    orderBy('startDate'),
                    limit(3)
                );
            }

            if (lastVisible && loadMore) {
                q = query(q, startAfter(lastVisible));
            }

            const querySnapshot = await getDocs(q);
            const eventData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            
            if (loadMore) {
                setEvents(prevEvents => [...prevEvents, ...eventData]);
            } else {
                setEvents(eventData);
            }
            
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.docs.length === 3);
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    useEffect(() => {
        if (isToday) {
            fetchEvents(null, null, true);
        } else if (startDate && endDate) {
            fetchEvents(startDate, endDate);
        }
    }, [isToday, startDate, endDate]);

    const handleDateRangeChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        setIsToday(false);
        setLastVisible(null);
        setHasMore(true);
    };

    const handleTodayEventsClick = () => {
        setIsToday(true);
        setStartDate(null);
        setEndDate(null);
        setLastVisible(null);
        setHasMore(true);
    };

    const handleLoadMore = () => {
        fetchEvents(startDate, endDate, isToday, true);
    };

    const handleResetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setIsToday(false);
        setLastVisible(null);
        setHasMore(true);
        fetchEvents();
    };

    return (
        <Container>
            <div className='mb-4' style={{display: "flex", justifyContent: 'flex-start', gap: '1rem', width:"100%"}}>
                <Button 
                    variant="primary" 
                    className='today-events'  
                    size="sm" 
                    style={{backgroundColor: "var(--bluecolor)", border:"none"}}
                    onClick={handleTodayEventsClick}
                >
                    TODAY
                </Button>
                <div className='d-none d-md-flex' style={{paddingTop:"8px",width:"100%"}}>
                    <DateRangePicker onDateRangeChange={handleDateRangeChange} />
                </div>
                <div className="d-md-none" style={{paddingTop:"8px"}}>
                    <NowDateRangePicker onEndDateChange={(date) => handleDateRangeChange(new Date(), date)} />
                </div>
            </div>

            <div style={{ textAlign:'left', width :"100%", overflow:"hidden", fontSize:"16px", paddingBottom:'30px'}}>
                <span className="text-with-separator" >
                    <Button 
                        variant="secondary" 
                        size="sm" 
                        onClick={handleResetFilters}
                    >
                        Reset Filters
                    </Button>
                </span>
            </div>

            {loading && events.length === 0 ? (
                <p>Loading events...</p>
            ) : events.length === 0 ? (
                <p>No events found.</p>
            ) : (
                events.map((event) => (
                    <Row key={event.id} className='event-card'>
                        <Col sm={2} xs={2} className="alignleft">
                            <div className="date-tag">
                                <div className="month">{format(new Date(event.startDate), 'MMM')}</div>
                                <div className="day">{format(new Date(event.startDate), 'd')}</div>
                            </div>
                        </Col>
                        <Col sm={3} xs={10} className="alignleft order-xs-2 order-sm-3" style={{ overflow: "hidden" }}>
                            <img src={event.imageUrl} style={{ maxWidth: "300px", width: "100%", aspectRatio: 'auto' }} alt={event.eventName} />
                        </Col>
                        <Col xs={2} className="alignleft d-xs-block d-sm-none"></Col>
                        <Col sm={7} xs={10} className="alignleft order-xs-3 order-sm-2 ">
                            <p>{format(new Date(event.startDate), 'MMMM d @ h:mm a')} - {format(new Date(event.endDate), 'h:mm a')}</p>
                            <h2><span class='event-name-link' onClick={() => handleEventClick(event.eventName, event.id)}>{event.eventName}</span></h2>
                            <p><span style={{ fontWeight: '600' }}>{event.venue}</span></p>
                            <p>{event.brief}</p>
                            <p>{event.rate}</p>
                        </Col>
                    </Row>
                ))
            )}

            <div className='separator mt-2 mb-2'></div>
            <Row>
                <Col xs={12} className='text-center'>
                    {hasMore && (
                        <Button 
                            className="nav-button" 
                            onClick={handleLoadMore}
                            disabled={loading || !hasMore}
                        >
                            {loading ? 'Loading...' : 'Load More'}
                        </Button>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default EventList;