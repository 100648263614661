import styled from 'styled-components';
import { motion } from 'framer-motion';

const Section = styled.section`
  background: linear-gradient(180deg, #f5f7fa, #c3cfe2);
  padding: 80px 20px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
`;

const QuoteCard = styled(motion.div)`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin: 20px auto;
  max-width: 700px;
  color: #555;
`;

const QuoteText = styled.p`
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 20px;
  line-height: 1.8;
`;

const Reference = styled.p`
  font-size: 1rem;
  color: #888;
  font-weight: bold;
`;

export default function BibleQuoteSection() {
  const quoteAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Section>
      <Title>Verse for the Week</Title>
      <QuoteCard
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.8 }}
        variants={quoteAnimation}
      >
        <QuoteText>
          "For I know the plans I have for you," declares the Lord, "plans to
          prosper you and not to harm you, plans to give you hope and a future."
        </QuoteText>
        <Reference>Jeremiah 29:11</Reference>
      </QuoteCard>
    </Section>
  );
}
