import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import img1 from '../../../images/blogupdate/off1.png';
import img2 from '../../../images/blogupdate/off2.png';
import img3 from '../../../images/blogupdate/off3.png';
import './BlogUpdate.css';
import ButtonComponent from '../Groups/ButtonComponent';

const WeHelpSection = () => {
  return (
    <div className="we-help-section">
      <Container>
        <Row className="justify-content-between">
          <Col lg={7} className="mb-5 mb-lg-0">
            <div className="imgs-grid">
              <div className="grid grid-1">
                <img src={img1} alt="Design 1" />
              </div>
              <div className="grid grid-2">
                <img src={img2} alt="Design 2" />
              </div>
              <div className="grid grid-3">
                <img src={img3} alt="Design 3" />
              </div>
            </div>
          </Col>
          <Col lg={5} className="ps-lg-5">
            <h2 className="section-title mb-4">
              Join our various events, groups and also have access to lots of contents
            </h2>
            <p>
              Our church has a wide array of groups and activities we are certain will
              interest you on this journey with Christ.
              Visit our various group pages learn about their activities, events
              and meeting day. 
              Dont also hesistate to react out to us regarding upcoming contents, services and events
            </p>
            
        
            <ButtonComponent to='/contact' children='Contact Us' width='160px'/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WeHelpSection;

		