import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import ParallaxHeader from '../Layouts/General/ParallaxHeader';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaMoneyBillWave } from 'react-icons/fa';
import Footer from '../Components/Footer/Footer';
import EventNotFound from '../Layouts/Events/EventNotFound';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';

const EventSingle = () => {
    const { eventid } = useParams();
    const { db } = useFirebase();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEvent = async () => {
            setLoading(true);
            const eventsRef = collection(db, 'events');
            const q = query(eventsRef, where('__name__', '==', eventid));
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                setEvent({ id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() });
            }

            setLoading(false);
        };

        fetchEvent();
    }, [db, eventid]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                <Spinner animation="border" role="status">
                    <span className="sr-only"></span>
                </Spinner>
            </div>
        );
    }

    if (!event) {
        return <EventNotFound />;
    }

    return(
        <>
        <SEO
            title={event.eventName}
            description={event.brief}
            name='Emmanuel Presbyterian Church.'
            type='events' 
        />
        <NavBarComponent />

        <ClearHeader 
            title={event.eventName} 
            breadcrumbs={['Home', 'Events', event.id ]} 
        />
        <div style={{marginBottom:"100px"}}></div>

         <Container className='alignleft text-dark'>
            <h1 className='event-single-title'>{event.eventName}</h1>
            <p className='event-single-description' dangerouslySetInnerHTML={{ __html: event.description }}></p>

            <div className='mb-5'></div>
            <h3> EVENT DETAILS</h3>
            <Row>
                <Col sm={4} xs={12} className="alignleft">
                    <p><FaCalendarAlt color='var(--redcolor)'/> <span>{event.startDate}</span></p>
                </Col>
                <Col sm={4} xs={12} className="alignleft">
                    <p><FaCalendarAlt color='var(--redcolor)'/> <span>{event.endDate}</span></p>
                </Col>
                <Col sm={4} xs={12} className="alignleft">
                    <p><FaClock color='var(--redcolor)'/> <span>{event.startTime}</span> - <span>{event.endTime}</span></p>
                </Col>

                <Col sm={4} xs={12} className="alignleft">
                    <p><FaMapMarkerAlt color='var(--redcolor)'/> <span>{event.venue}</span></p>
                </Col>
                <Col sm={4} xs={12} className="alignleft">
                    <p><FaMoneyBillWave color='var(--redcolor)'/> <span>{event.rate}</span></p>
                </Col>
            </Row>
         </Container>
         <div style={{marginTop:"20px",marginBottom:"50px"}}></div>
         <Footer />
        </>
    )
};

export default EventSingle;