import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { useFirebase } from '../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import { 
  FaEye, 
  FaCrosshairs, 
  FaCross, 
  FaPrayingHands,
  FaChurch,
  FaBible,
  FaArrowRight,
  FaHeart,
  FaHandHoldingHeart,
  FaUsers
} from 'react-icons/fa';
import { MdGroups } from 'react-icons/md';
import { Spinner } from 'react-bootstrap';

const styles = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { transform: translateX(-20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes pulseIcon {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.vision-mission-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
  animation: fadeIn 0.8s ease-out;
}

.section-title {
  color: #2c3e50;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section-subtitle {
  color: #6c757d;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.main-card {
  border: none;
  border-radius: 15px;
  transition: all 0.3s ease;
  height: 100%;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-out;
}

.main-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--redcolor);
  animation: pulseIcon 2s infinite;
}

.vm-card-title {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.vm-card-text {
  color: #5a6a7a;
  font-size: 1rem;
  line-height: 1.6;
}

.learn-more-btn {
  margin-top: 1rem;
  background-color: transparent;
  border: 2px solid var(--redcolor);
  color: var(--redcolor);
  transition: all 0.3s ease;
}

.learn-more-btn:hover {
  background-color: var(--redcolor);
  color: white;
}

.beliefs-section {
  margin-top: 6rem;
  padding: 3rem 0;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.belief-tabs {
  border: none;
  margin-bottom: 2rem;
}

.belief-tabs .nav-link {
  color: #5a6a7a;
  border: none;
  padding: 1rem 2rem;
  transition: all 0.3s ease;
}

.belief-tabs .nav-link.active {
  color: var(--redcolor);
  background-color: transparent;
  border-bottom: 3px solid var(--redcolor);
}

.belief-card {
  border: none;
  border-radius: 12px;
  background: white;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  animation: slideIn 0.5s ease-out;
}

.belief-card:hover {
  transform: translateX(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.belief-icon {
  color: var(--redcolor);
  font-size: 1.5rem;
  margin-right: 1rem;
}

.belief-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.belief-text {
  font-size: 1rem;
  color: #5a6a7a;
}

.stat-card {
  text-align: center;
  padding: 2rem;
  margin-top: 3rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  animation: fadeIn 1s ease-out;
}

.stat-icon {
  font-size: 2rem;
  color: var(--redcolor);
  margin-bottom: 1rem;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #6c757d;
  font-size: 1rem;
}

.detail-modal .modal-content {
  border-radius: 15px;
  border: none;
}

.detail-modal .modal-header {
  border-bottom: none;
  padding: 2rem 2rem 1rem;
}

.detail-modal .modal-body {
  padding: 1rem 2rem 2rem;
}

@media (max-width: 768px) {
  .vision-mission-section {
    padding: 40px 0;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .vm-card-title {
    font-size: 1.3rem;
  }
  
  .belief-tabs .nav-link {
    padding: 0.75rem 1rem;
  }
}
`;

const VisionMissionBeliefs = () => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [activeTab, setActiveTab] = useState('core');
  const { db } = useFirebase();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setLoading(true);
        const docRef = await getDoc(doc(db, 'aboutContent', 'vmb'));
        if (docRef.exists()) {
          setContent(docRef.data());
        } else {
          setError('Content not found');
        }
      } catch (err) {
        console.error("Error fetching content:", err);
        setError('Failed to load content');
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [db]);

  const handleCardClick = (type) => {
    setModalContent({
      title: type === 'vision' ? 'Our Vision'
           : type === 'mission' ? 'Our Mission'
           : 'Our Values',
      content: content[type],
      icon: type === 'vision' ? <FaEye />
           : type === 'mission' ? <FaCrosshairs />
           : <FaCross />
    });
    setModalShow(true);
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  if (!content) return null;

  return (
    <>
      <style>{styles}</style>
      <section className="vision-mission-section">
        <Container>
          <div className="section-header">
            <h2 className="section-title">Our Foundation</h2>
            <p className="section-subtitle">Guided by faith, driven by purpose, united in love</p>
          </div>

          <Row className="g-4">
            {[
              { type: 'vision', title: 'Our Vision', icon: FaEye },
              { type: 'mission', title: 'Our Mission', icon: FaCrosshairs },
              { type: 'values', title: 'Our Values', icon: FaCross }
            ].map((item) => (
              <Col md={4} key={item.type}>
                <Card className="main-card p-4 text-center">
                  <Card.Body>
                    <item.icon className="card-icon" />
                    <h3 className="vm-card-title">{item.title}</h3>
                    <p className="vm-card-text">
                      {content[item.type]?.substring(0, 150)}...
                    </p>
                    <Button 
                      className="learn-more-btn"
                      onClick={() => handleCardClick(item.type)}
                    >
                      Learn More <FaArrowRight className="ms-2" />
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div className="beliefs-section">
            <h3 className="text-center mb-4">What We Believe</h3>
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="belief-tabs justify-content-center mb-4"
            >
              <Tab eventKey="core" title="Core Beliefs" />
              <Tab eventKey="values" title="Values" />
              <Tab eventKey="practices" title="Practices" />
            </Tabs>

            <Row>
              {content.beliefs
                ?.filter(belief => belief.category === activeTab)
                .map((belief, index) => (
                <Col md={6} key={index}>
                  <Card className="belief-card p-3">
                    <Card.Body className="d-flex">
                      {getBeliefIcon(index)}
                      <div>
                        <h4 className="belief-title">{belief.title}</h4>
                        <p className="belief-text mb-0">{belief.description}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <Row className="mt-5">
            {[
              { icon: FaUsers, number: '1000+', label: 'Community Members' },
              { icon: FaHeart, number: '50+', label: 'Ministry Programs' },
              { icon: FaHandHoldingHeart, number: '100+', label: 'Volunteers' }
            ].map((stat, index) => (
              <Col md={4} key={index}>
                <div className="stat-card">
                  <stat.icon className="stat-icon" />
                  <div className="stat-number">{stat.number}</div>
                  <div className="stat-label">{stat.label}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          centered
          className="detail-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              {modalContent.icon}
              <span className="ms-2">{modalContent.title}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{modalContent.content}</p>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

// Helper function to get belief icons
const getBeliefIcon = (index) => {
  const icons = [FaPrayingHands, FaBible, FaChurch, MdGroups];
  const IconComponent = icons[index % icons.length];
  return <IconComponent className="belief-icon" />;
};

export default VisionMissionBeliefs;