import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TeamMemberCard from './TeamMemberCard';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../Context/FirebaseContext';
import ButtonComponent from '../IndexLayout/Groups/ButtonComponent';

const PresbyteryList = () => {
  const [presbyters, setPresbyters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { db } = useFirebase();

  useEffect(() => {
    const fetchPresbyters = async () => {
      try {
        // First, get the presbytery group
        const groupsQuery = query(collection(db, 'groups'), where('type', '==', 'presbyters'));
        const groupSnapshot = await getDocs(groupsQuery);
        
        if (groupSnapshot.empty) {
          setError('');
          setLoading(false);
          return;
        }

        const presbyteryGroupId = groupSnapshot.docs[0].id;

        // Then, get all members in this group
        const membersQuery = query(
          collection(db, 'members'), 
          where('group', 'array-contains', presbyteryGroupId)
        );
        const membersSnapshot = await getDocs(membersQuery);

        const presbytersData = membersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          role: 'Presbyter' // You might want to fetch this from another field if available
        }));

        setPresbyters(presbytersData);
      } catch (err) {
        console.error("Error fetching presbyters:", err);
        setError('Failed to fetch presbyters');
      } finally {
        setLoading(false);
      }
    };

    fetchPresbyters();
  }, [db]);

  const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
  }

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        
      </Container>
    );
  }

  if (presbyters.length === 0) {
    return (
      <Container className="text-center mt-5">
        
      </Container>
    );
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Container fluid className="presbytery-list bg-dark text-light py-5">
      <Row className="justify-content-between align-items-center mb-4">
        <Col style={{paddingLeft:"5%", paddingRight:'5%'}}>
          <h2 className="text-light" style={{textAlign:"left"}}>PRESBYTERS</h2>
          <h3 className="text-danger" style={{textAlign:"left"}}>Current</h3>
        </Col>
        <Col xs="auto" style={{ paddingRight:"50px"}}>
          {/*
          <Button  style={{
              padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
              fontSize: 'clamp(14px, 1vw, 18px)',
              backgroundColor: 'transparent',
              color: 'white',
              border: '2px solid var(--redcolor)',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 600,
              transition: 'all 0.3s ease',
            }}>
               Past Presbyters
          </Button>
          */}
          
        </Col>
      </Row>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={false}
        infinite={true}
        autoPlay={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
        keyBoardControl={true}
        
      >
        {presbyters.map((presbyter) => (
          <div key={presbyter.id} className="d-flex justify-content-center">
            <TeamMemberCard
              name={`${capitalizeFirstLetter(presbyter.title)} ${presbyter.firstName} ${presbyter.lastName}`}
              role={presbyter.role}
              imageUrl={presbyter.profilePicUrl}
            />
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default PresbyteryList;