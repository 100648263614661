import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { FiChevronUp } from 'react-icons/fi';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './Events.css';

const NowDateRangePicker = ({ onEndDateChange }) => {
  const [endDate, setEndDate] = useState(new Date());
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndCalendarOpen(false);
    onEndDateChange(date);
  };

  const CustomInput = ({ value, onClick, isOpen }) => (
    <div 
      className="custom-input d-flex justify-content-between align-items-center p-3 border rounded"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <span>{formatDate(value)}</span>
      <FiChevronUp className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
    </div>
  );

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem" }}>
            <div className='flex-row-xs'> 
              <span><strong>Now</strong></span>
            </div>   
            <div className='flex-row-xs'> 
              <span>-</span>
              <span>
                <DatePicker
                  onChange={handleEndDateChange}
                  value={endDate}
                  format="y-MM-dd"
                  calendarIcon={null}
                  clearIcon={null}
                  onCalendarOpen={() => setIsEndCalendarOpen(true)}
                  onCalendarClose={() => setIsEndCalendarOpen(false)}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  customInput={
                    <CustomInput
                      value={endDate}
                      isOpen={isEndCalendarOpen}
                    />
                  }
                />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NowDateRangePicker;