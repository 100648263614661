import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DailyEventsList = () => {
  const { db } = useFirebase();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const fetchEvents = async (date) => {
    setLoading(true);
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const q = query(
        collection(db, 'events'),
        where('startDate', '==', formattedDate),
        where('deleted', '==', false),
        where('status', '==', 'active'),
        orderBy('startDate')
      );

      const querySnapshot = await getDocs(q);
      const eventData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEvents(eventData);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleEventClick = (eventname, eventId) => {
    navigate(`/events/${createSlug(eventname)}/${eventId}`);
};

const  createSlug = (string) => {
    string = string.toLowerCase();
    string = string.replace(/[^a-z0-9 ]/g, '');
    string = string.replace(/\s+/g, '-');
    string = string.replace(/^-|-$/g, '');
    if (string === '') {
      return 'slug'; 
    }
  
    return string;
} 

  return (
    <Container className='alignleft'>
      <h1 className="mb-4">Daily Events</h1>
      <Row className="mb-4">
        <Col>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            className="form-control"
          />
        </Col>
      </Row>

      {loading ? (
        <p>Loading events...</p>
      ) : events.length === 0 ? (
        <p>No events found for {format(selectedDate, 'MMMM d, yyyy')}.</p>
      ) : (
        events.map((event) => (
          <Row key={event.id} className="event-card mb-4">
            <Col sm={2} xs={2} className="alignleft">
              <div className="date-tag">
                <div className="month">{format(new Date(event.startDate), 'MMM')}</div>
                <div className="day">{format(new Date(event.startDate), 'd')}</div>
              </div>
            </Col>
            <Col sm={3} xs={10} className="alignleft order-xs-2 order-sm-3">
              <img src={event.imageUrl} style={{ maxWidth: "300px", width: "100%", aspectRatio: 'auto' }} alt={event.eventName} />
            </Col>
            <Col sm={7} xs={10} className="alignleft order-xs-3 order-sm-2">
              <p>{format(new Date(event.startDate), 'h:mm a')} - {format(new Date(event.endDate), 'h:mm a')}</p>
              <h2><span class='event-name-link' onClick={() => handleEventClick(event.eventName, event.id)}>{event.eventName}</span></h2>
              <p><span style={{ fontWeight: '600' }}>{event.venue}</span></p>
              <p>{event.brief}</p>
              <p>{event.rate}</p>
            </Col>
          </Row>
        ))
      )}
    </Container>
  );
};

export default DailyEventsList;